import CryptoJS from 'crypto-js'

const decryptAES_ECB_PKCS7Padding = (decryptString, key) => {
    key  = CryptoJS.enc.Utf8.parse(key);
	let decrypt = CryptoJS.AES.decrypt(decryptString, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
	return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}

const uint8ArrayToHex = (_array) => {
    let hexString = ''
    _array.forEach(b => {
        hexString += b.toString(16).padStart(2, '0')
    })
    return hexString
}

const hexStringToUint8Array = (hexString) => {
    hexString = hexString.replace(/\s/g, '');
    const hexArray = hexString.match(/.{1,2}/g);
    const uint8Array = Uint8Array.from(hexArray, hex => parseInt(hex, 16))

    return uint8Array
}

const createSecretKey = async (aes_key) => {
    const keyBytes = new Uint8Array(aes_key.length)
    for (let i = 0; i < aes_key.length; i++) {
        keyBytes[i] = aes_key.charCodeAt(i)
    }

    return await window.crypto.subtle.importKey(
        'raw',
        keyBytes,
        'AES-GCM',
        false,
        ['encrypt', 'decrypt']
    )
}

const encryptAES_GCM_NoPadding = async (plaintext, aes_key) => {
    const key = await createSecretKey(aes_key)
    const iv = window.crypto.getRandomValues(new Uint8Array(12))

    let encrypted = await window.crypto.subtle.encrypt({
        name: "AES-GCM",
        iv: iv
    }, key, new TextEncoder().encode(plaintext))

    console.log(encrypted)
    return `${uint8ArrayToHex(iv)}:${uint8ArrayToHex(Array.from(new Uint8Array(encrypted)))}`
}

const decryptAES_GCM_NoPadding = async (ciphertext, aes_key) => {
    const [_iv, body] = ciphertext.split(':')
    const encrypted = hexStringToUint8Array(body)

    const key = await createSecretKey(aes_key)
    const iv = hexStringToUint8Array(_iv)

    let decryptedData = await window.crypto.subtle.decrypt({
        name: "AES-GCM",
        iv: iv
    }, key, encrypted)

    return new TextDecoder().decode(decryptedData)
}

const AES_KEY = window.atob('aXBhdGgkJV5hZmpAMjAyNA==')

const encryptAES_GCM = async (plaintext) => {
    return await encryptAES_GCM_NoPadding(plaintext, AES_KEY)
}

const decryptAES_GCM = async (ciphertext) => {
    return await decryptAES_GCM_NoPadding(ciphertext, AES_KEY)
}

window.encryptAES_GCM_NoPadding = encryptAES_GCM_NoPadding
window.decryptAES_GCM_NoPadding = decryptAES_GCM_NoPadding

window.encryptAES_GCM = encryptAES_GCM
window.decryptAES_GCM = decryptAES_GCM

export {
    decryptAES_ECB_PKCS7Padding,
    encryptAES_GCM,
    decryptAES_GCM
}