import React from 'react';
import style from './agreement.scss';

import { withTranslation } from "react-i18next";

class Agreement extends React.Component {

    t = this.props.t

    render() {
        return <div className={style.body}>
          {/* 软件使用协议 */}
    <h2>{this.t('agreement.k001')}</h2>
    {/* 1、定义 */}
    <h3>{this.t('agreement.k002')}</h3>
    <p>
    {this.t('agreement.k003')}
    </p>
    <p>
    {this.t('agreement.k004')}
    </p>
    <p>
    {this.t('agreement.k005')}
    </p>
    <h3>{this.t('agreement.k006')}</h3>
    <p>
    {this.t('agreement.k007')}
    </p>
    <h3>{this.t('agreement.k008')}</h3>
    <p>
    {this.t('agreement.k009')}
    </p>
    <h3>{this.t('agreement.k010')}</h3>
    <p>
    {this.t('agreement.k011')}
    </p>
    <h3>{this.t('agreement.k012')}</h3>
    <p>
    {this.t('agreement.k013')}
    </p>
    <p>
    {this.t('agreement.k014')}
    </p>
    <p>
    {this.t('agreement.k015')}
    </p>
    <p>
    {this.t('agreement.k016')}
    </p>
    <p>
    {this.t('agreement.k017')}
    </p>
    <p>
    {this.t('agreement.k018')}
    </p>
    <p>
    {this.t('agreement.k019')}
    </p>
    <p>
    {this.t('agreement.k020')}
    </p>
    <p>
    {this.t('agreement.k021')}
    </p>
    <p>
    {this.t('agreement.k022')}
    </p>
    <p>
    {this.t('agreement.k023')}
    </p>
    <h3>{this.t('agreement.k024')}</h3>
    <p>
    {this.t('agreement.k025')}
    </p>
    <p>
    {this.t('agreement.k026')}
    </p>
    <p>
    {this.t('agreement.k027')}
    </p>
    <p>
    {this.t('agreement.k028')}
    </p>
    <p>
    {this.t('agreement.k029')}
    </p>
    <p>
    {this.t('agreement.k030')}
    </p>
    <p>
    {this.t('agreement.k031')}
    </p>
    <h3>
    {this.t('agreement.k032')}
    </h3>
    <p>
    {this.t('agreement.k033')}
    </p>
    <p>
    {this.t('agreement.k034')}
    </p>
    <h3>
    {this.t('agreement.k035')}
    </h3>
    <p>
    {this.t('agreement.k036')}
    </p>
    <h3>{this.t('agreement.k037')}</h3>
    <p>
    {this.t('agreement.k038')}
    </p>
    </div>
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }
}

export default withTranslation()(Agreement);
