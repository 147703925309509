import { ipay_url, feishu_open_url, appId, oauthType, loadJsSdk } from './../util/config'
import webService from './../util/webService';
import { isClient, vmIsIOS, isDevice } from './bridgeSmartcomTrip'
import * as dd from 'dingtalk-jsapi';

let isTripClient = isClient();
let isTripClientIOS = isTripClient ? vmIsIOS() : false;

/**
 * 校验手机号格式
 * @param {*} phone 
 * @returns 
 */
const checkPhoneNumber = (phone) => {
  if (phone) {
    if (/^1\d{10}$/.test(phone)) {
      return true
    } else {
      return false
    }
  }
  return false;
}

/**
 * 数字格式化成两位形式，不足前补0
 * @param {*} s 
 * @returns 
 */
const numberTo2Digit = (s) => {
  return ('00' + s).slice(-2)
}

/**
 * 秒数转时分秒结构体
 * @param {*} s 
 */
const secondTohh_mm_ssStruct = (s) => {
  let _s = s;
  let h = Math.trunc(_s / 3600);
  _s = _s - 3600 * h;
  let m = Math.trunc(_s / 60);
  _s = _s - 60 * m;
  return {
    h: h,
    m: m,
    s: _s
  };
}

/**
 * 秒数转时分秒字符串
 * @param {*} s 
 * @returns 
 */
const secondTohh_mm_ss = (s) => {
  let struct = secondTohh_mm_ssStruct(s);
  if (struct.h) {
    return `${numberTo2Digit(struct.h)}:${numberTo2Digit(struct.m)}:${numberTo2Digit(struct.s)}`
  } else {
    return `${numberTo2Digit(struct.m)}:${numberTo2Digit(struct.s)}`
  }
}

const dge = () => {
  let isMobile = window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i); // 是否手机端
  // 是否微信
  let isWx = /micromessenger/i.test(navigator.userAgent);
  // 是否企业微信
  let isComWx = /wxwork/i.test(navigator.userAgent);
  // 是否小程序
  let isMiniProgram = /miniProgram/i.test(navigator.userAgent);
  // 是否飞书
  let isFeishu = /feishu/i.test(navigator.userAgent) || /workplace/i.test(navigator.userAgent);
  // 是否石油商旅
  let isSmartcomTrip = /SmartcomTrip/i.test(navigator.userAgent);
  // 是否是京东
  let isJd = /jdapp/i.test(navigator.userAgent)
  // 是否是京东小程序
  let isJdMp = /jdmp/i.test(navigator.userAgent)
  // 是否钉钉
  let isDingDing = /DingTalk/i.test(navigator.userAgent)

  // 手机端企业微信
  if (isComWx && isMobile) {
    return 'com-wx-mobile'
  }
  // PC端企业微信
  else if (isComWx && !isMobile) {
    return 'com-wx-pc'
  }
  // 手机端飞书
  else if (isFeishu && isMobile) {
    return 'feishu-mobile'
  }
  // PC端飞书
  else if (isFeishu && !isMobile) {
    return 'feishu-pc'
  }
  // 手机端微信
  else if (isWx && isMobile && !isMiniProgram) {
    return 'wx-mobile';
  }
  // 手机端微信小程序
  else if (isWx && isMobile && isMiniProgram) {
    return 'wx-mini'
  }
  // PC端微信
  else if (isWx && !isMobile) {
    return 'wx-pc';
  }
  // 手机端石油商旅
  else if (isSmartcomTrip && isMobile) {
    return 'smartcomtrip-mobile';
  }
  // 手机端京东小程序
  else if (isJd && isMobile && isJdMp) {
    return 'jd-mini'
  }
  // 钉钉
  else if (isDingDing && isMobile) {
    return 'ding-ding'
  }
  // 其他
  else {
    return 'other'
  }
}

/**
 * 作做支付连接
 * @param {*} orderId 
 * @param {*} backuri 
 */
const createPayUrl = (orderId, backuri) => {
  // 飞书
  let feishu = feishu_open_url;
  // let feishu = feishu_open_url + backuri;
  // 回调 url
  let callBackUrl = ''
  // 设备企业支付标识
  let Appid = ''
  // 支付 url
  let href = ''
  // 判断型号
  let ua = dge()

  if (ua.indexOf('feishu') !== -1) {
    // 飞书
    sessionStorage.setItem('hash', '#');
    // sessionStorage.setItem('hash', '#' + backuri);
    callBackUrl = encodeURIComponent(`${feishu}`);
    // feishu = 'None'
    // callBackUrl = feishu
    Appid = 'feishu'
  } else if (ua.indexOf('smartcomtrip-mobile') !== -1) {
    // 判断石油IOS或Android传给支付中心标识，支付中心进行判断标识进行回退
    if (isTripClientIOS) {
      Appid = 'smartcomtrip_mobile_IOS';
    } else {
      Appid = 'smartcomtrip_mobile_Android'
    }
  }
  // 企业微信支付
  if (oauthType === '1') {
    href = backuri
  } else {
    href = ipay_url.replace('$payOrderid$', orderId);
    href = href.replace('$callBackUrl$', callBackUrl);
    href = href.replace('$appid$', Appid);
    let grayscale = sessionStorage.getItem('grayscale');
    if (grayscale) {
      href = href.replace('$preEnv$', 'grayscale_environment')
    } else {
      href = href.replace('$preEnv$', '')
    }
  }

  return href;
}

const ipay = (data, backuri) => {
  console.log(navigator.userAgent)
  if(dge() === 'jd-mini'){
    console.log('data', data)
    console.log('11111', `/pages/pay/index?orderId=${data.prepayid}&paySign=${data.paySign ? data.paySign : ""}&payablePrice=${data.package}`)
    window.jd.miniProgram.navigateTo({url: `/pages/pay/index?orderId=${data.prepayid}&paySign=${data.paySign ? data.paySign : ""}&payablePrice=${data.package}`});
  } else if(dge() === 'wx-mini' && ['zhonghai', 'XCX032424', 'wx8c2f90314ba125f9', 'wx276b26f37e18d78a'].includes(appId)) {
    window.wx.miniProgram.navigateTo({url: `/pages/index/pay?timeStamp=${data.timeStamp}&nonceStr=${data.nonceStr}&package=${encodeURIComponent(data.package)}&paySign=${data.paySign}&signType=${data.signType}`})
  } else {
    let href = createPayUrl(data.tranOrderNo ? data.tranOrderNo : data.transaction_no, backuri);
    // || oauthType === '11'
    if (oauthType === '1') {
      window.location.replace(href)
    } else {
      window.location.href = href
    }
  }
}

const payType = () => {
  if (['zhonghai', 'XCX032424'].includes(appId)) {
    return Number(2)
  } else if (oauthType === '1') {
    return Number(3)
  } else if (oauthType === '15') {
    // TODO: 区分新老小程序  
    if (['906235CB607873B471D28180011812FC'].includes(appId)) {
      // 新小程序MP.store模式
      return Number(26)
    } else {
      // 老小程序MP.pay模式
      return Number(23)
    }
    
  } else {
    return Number(1)
  }
}

/**
 * 判断客户端使用地图缩放级别
 * @returns 
 */
const clientMapZoom = () => {
  // 对石油 ios 手机做的处理
  if (isTripClientIOS) {
    return [3, 18]
  } else {
    return [3, 19]
  }
}

let stringToBase64 = (str) => {
  return Buffer.from(str, 'utf-8').toString('base64')
}

let base64ToString = (base64) => {
  return Buffer.from(base64, 'base64').toString('utf-8')
}

/**
 * 安全base64编码方法
 * @param {*} str 
 */
let stringToBase64Safe = (str) => {
  let _str = stringToBase64(str)
  _str = _str.replace(/\+/g, '-')
  _str = _str.replace(/\//g, '_')
  return _str
}

/**
 * 防抖
 * 
 */
let debounce = (func, delay) => {
  let timer = null;
  //返回一个新的函数
  return function (...args) {
    //每次调用这个函数，都会去判断是否还有timer，有(之前的没执行)就取消掉
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      //设置定时器，到一定时间就执行需要防抖执行的函数
      func.apply(this, args);
    }, delay);
  };
}

let debounceUpgrades = (func, delay, immediate = false) => {
  let timer;
  return function (...args) {
      const context = this;
      const callNow = immediate && !timer;
      clearTimeout(timer);
      timer = setTimeout(() => {
          timer = null;
          if (!immediate) func.apply(context, args);
      }, delay);
      if (callNow) func.apply(context, args);
  };
}

class ForgeRouter {
  constructor(callback) {
    this.url = window.location.pathname + window.location.hash;
    this.callback = callback;
    this.isCallbackCompleted = false
  }

  onpopstate() {
    window.history.pushState(null, null, this.url);
    let oldonpopstate = window.onpopstate;
    window.onpopstate = (e) => {
      window.onpopstate = oldonpopstate;
      if (this.isCallbackCompleted) {
        return
      }
      this.isCallbackCompleted = true
      try {
        this.callback();
      } catch (exp) {
        console.error(exp);
      }
    }
  }

  manualOperation() {
    if (this.isCallbackCompleted) {
      return
    }
    this.isCallbackCompleted = true
    try {
      this.callback(true);
    } catch (exp) {
      console.error(exp);
    }
  }

}

class FeiShuJsSdk {
  constructor() {
    this._share = (shareData) => {
      window.h5sdk.biz.util.share({
        ...shareData,
        onSuccess: function (result) { }
      });
    }
  }
  share = async (shareData) => {
    if (!window.feishuConfigOk) {
      let { data } = await webService.user.getConfigParams({
        oauthType: oauthType,
        appId: appId,
        url: window.location.origin + window.location.pathname
      });
      if (data.code === 0 && data.data) {
        let jssdkSignRes = data.data;
        window.h5sdk.config({
          appId: appId,
          timestamp: jssdkSignRes.timeStamp * 1,
          nonceStr: jssdkSignRes.nonceStr,
          signature: jssdkSignRes.signature,
          jsApiList: [
            'share'
          ],
          onSuccess: (result) => {
            window.feishuConfigOk = true;
            this._share(shareData);
          },
          onFail: function (result) {
            window.feishuConfigOk = false;
          }
        });
      }
    } else {
      this._share(shareData);
    }

  }
}

class WeChatWorkJsSdk {
  /**
   * 初始化config
   */
  init = async(not3rd) => {
    return new Promise(async (ok, err) => {
      if (!window.WeChatWorkConfigOk) {
        let { data } = await webService.user.getConfigParams({
          oauthType: oauthType,
          appId: appId,
          url: encodeURIComponent(window.location.origin + window.location.pathname)
        });
        if (data.code === 0 && data.data) {
          let jssdkSignRes = data.data;
          jssdkSignRes.jsApiList = ['agentConfig', 'shareAppMessage', 'getLocation']
          //eslint-disable-next-line
          wx.config({
            //debug: true,
            beta: true,
            appId: oauthType === '11' ? jssdkSignRes.secretKey : jssdkSignRes.corpid,
            timestamp: jssdkSignRes.timestamp,
            nonceStr: jssdkSignRes.nonceStr,
            signature: jssdkSignRes.signature,
            jsApiList: ['agentConfig', 'shareAppMessage']
          });
          //eslint-disable-next-line
          wx.error((res) => {
            console.error('企业微信jssdk签名失败', res)
            ok(res)
          })
          //eslint-disable-next-line
          wx.ready(async (rr) => {
            console.log('wx.config 完成', rr)
            if (not3rd) {
              window.WeChatWorkConfigOk = true;
              console.log('wx.config 成功', rr)
            } else {
              if (oauthType === '11') {
                jssdkSignRes.signature = jssdkSignRes.agentSignature
              }
              //eslint-disable-next-line
              let res = await agentConfig(wx, jssdkSignRes)
              if (res.errMsg === 'agentConfig:ok') {
                window.WeChatWorkConfigOk = true;
                console.log('agentConfig 成功')
              } else {
                window.WeChatWorkConfigOk = false;
                if (res.errMsg.indexOf('function not exist') > -1) {
                  alert('版本过低请升级')
                }
              }
            }
            ok()
          })

        }
      } else {
        ok()
      }
    })
    
  }

  /**
   * 分享
   * @param {*} shareData 
   */
  share = async (shareData) => {
    if (window.WeChatWorkConfigOk) {
      //eslint-disable-next-line
      wx.invoke("shareAppMessage", { ...shareData }, 
        function (res) {
          console.log('res', res)
          if (res.err_msg === "shareAppMessage:ok") {
            //正确处理
            console.log('分享成功')
          } else {
            console.log('分享失败', res)
            console.log(res.err_msg)
            //错误处理
          }
        }
      );
    } else {
      console.log(window.WeChatWorkConfigOk, '分享方法未能成功调用')
    }
  }

  getLocation = async () => {
    return new Promise((ok, err) => {
      if (window.WeChatWorkConfigOk) {
        console.log('wx.getLocation 开始')
        //eslint-disable-next-line
        wx.getLocation({
          type: 'wgs84',
          success: function (res) {
            // let latitude = res.latitude;
            // var longitude = res.longitude;
            // var speed = res.speed;
            // var accuracy = res.accuracy;
            ok(res)
          },
          fail: function(res) {
            console.log('企业微信jssdk定位失败', res)
            ok({})
          }
        });
      } else {
        console.log(window.WeChatWorkConfigOk, '获取位置方法未能调用成功')
        ok({})
      }
    })
  }

}
// 强生jssjk
class JnjJssdk {
  /**
   * 初始化config
   */
  init = async() => {
    return new Promise(async (ok, err) => {
      if (!window.JNJWeChatWorkConfigOk) {
        console.log('开始加载强生JSSDK')
        await loadJsSdk()
        console.log('完成加载强生JSSDK')
        window.JNJWeChatWorkConfigOk = true;
        ok()
      } else {
        console.log('已经加载强生JSSDK')
        ok()
      }
    })
    
  }

  /**
   * 分享
   * @param {*} shareData 
   */
  share = async (shareData) => {
    if (window.JNJWeChatWorkConfigOk) {
      //eslint-disable-next-line
      wx.invoke("shareAppMessage", { ...shareData }, 
        function (res) {
          if (res.err_msg === "shareAppMessage:ok") {
            //正确处理
            console.log('分享成功')
          } else {
            console.log(res.err_msg)
            //错误处理
          }
        }
      );
    } else {
      console.log(window.JNJWeChatWorkConfigOk, '分享方法未能成功调用')
    }
  }

  getLocation = async () => {
    return new Promise((ok, err) => {
      if (window.JNJWeChatWorkConfigOk) {
        console.log('调用强生JSSDK，开始获取坐标', window.JNJWeChatWorkConfigOk)
        //eslint-disable-next-line
        wx.getLocation({
          type: 'wgs84',
          success: function (res) {
            ok(res)
          },
          fail: function(res) {
            console.log('调用强生JSSDK定位失败', res)
            ok({})
          }
        });
      } else {
        console.log(window.JNJWeChatWorkConfigOk, 'JNJ获取坐标方法未能成功调用')
        ok({})
      }
    })
  }
}

async function agentConfig(wx, config) {
  console.info('wx.agentConfig', config)
  if (window.agentConfigSucceed === true) {
    return
  }
  return new Promise(async (resolve, reject) => {
    await waitForAgentConfigReady(wx)
    console.log('...config', ...config)
    wx.agentConfig({ ...config, success: resolve, fail: reject })
  }).then(
    res => {
      window.agentConfigSucceed = true
      return res;
    },
    error => {
      console.log('wx.agentConfig fail', error)
    }
  )
}

function waitForAgentConfigReady(wx) {
  if (wx.agent !== undefined) {
    return
  }
  return new Promise(resolve => {
    detectAgentConfig(wx, resolve())
  })
}

function detectAgentConfig(wx, resolve) {
  if (wx.agentConfig !== undefined) {
    resolve();
    return;
  }
  setTimeout(() => {
    detectAgentConfig(wx, resolve)
  })
}

class DingJsSdk {

  /**
   * 初始化
   */
  init = async () => {
    return new Promise(async (ok, err) => {
      if (!window.DingConfigOk) {
        let { data } = await webService.user.getConfigParams({
          oauthType: oauthType,
          appId: appId,
          url: window.location.origin + window.location.pathname
        });
        if (data.code === 0 && data.data) {
          let jssdkSignRes = data.data;
          let configParams = {
            agentId: jssdkSignRes.agentid,
            corpId: jssdkSignRes.corpid,
            timeStamp: jssdkSignRes.timestamp,
            nonceStr: jssdkSignRes.nonceStr,
            signature: jssdkSignRes.signature,
            type: 0,
            jsApiList: ['biz.util.share', 'device.geolocation.get']
          }
          console.log(configParams)
          //eslint-disable-next-line
          dd.config(configParams);
          dd.error(function (err) {
            window.DingConfigOk = false;
            console.log('钉钉jsapi config失败: ' + JSON.stringify(err));
            ok()
          })
          dd.ready(() => {
            window.DingConfigOk = true;
            console.log('钉钉jsapi config成功: ' + window.DingConfigOk);
            ok()
          })
        } else {
          err({
            message: '未得到钉钉签名'
          })
        }
      } else {
        ok()
      }
    })
  }

  /**
   * 定位
   * @returns 
   */
  getLocation = async () => {
    return new Promise((ok, err) => {
      if (window.DingConfigOk) {
        //eslint-disable-next-line
        dd.device.geolocation.get({
          targetAccuracy: 50,
          coordinate: 1,
          withReGeocode: false,
          useCache: false,
          onSuccess: (result) => {
            ok({
              longitude: result.longitude,
              latitude: result.latitude
            })
          },
          onFail: (err) => {
            console.log('钉钉JSSDK定位调用失败+', err, window.DingConfigOk)
          }
        })
      } else {
        console.log(window.DingConfigOk, '钉钉JSSDK定位调用失败')
        ok({})
      }
    })
  }

  /**
   * 分享
   * @param {*} shareData 
   */
  share = async (shareData) => {
    if (window.DingConfigOk) {
      dd.biz.util.share({
        type: 0,//分享类型，0:全部组件 默认；1:只能分享到钉钉；2:不能分享，只有刷新按钮
        url: shareData.link,
        title: shareData.title,
        content: shareData.desc,
        image: shareData.imgUrl,
        onSuccess: function () {
          console.log('分享成功')
        },
        onFail: function (err) {
          console.log('分享失败', err)
        }
      });
    }
  }
}

const copy = (value, cb) => {
  // 动态创建 textarea 标签
  const textarea = document.createElement('textarea')
  // 将该 textarea 设为 readonly 防止 iOS 下自动唤起键盘，同时将 textarea 移出可视区域
  textarea.readOnly = 'readonly'
  textarea.style.position = 'absolute'
  textarea.style.left = '-9999px'
  // 将要 copy 的值赋给 textarea 标签的 value 属性  
  textarea.value = value
  // 将 textarea 插入到 body 中
  document.body.appendChild(textarea)
  // 选中值并复制
  textarea.select()
  textarea.setSelectionRange(0, textarea.value.length)
  document.execCommand('Copy')
  document.body.removeChild(textarea)
  if (cb && Object.prototype.toString.call(cb) === '[object Function]') {
    cb()
  }
}

window.dge = dge;

function matchParameter(arge) {
  let query = {};

  arge = arge[0] === '?' ? arge.substring(1) : arge;
  let arges = arge.split('&');
  for (let i in arges) {
    let item = arges[i];
    if (item && item.indexOf('=') > 0) {
      let kv = item.split('=');
      query[kv[0]] = kv[1];
    }
  }

  return query;
}

/**
 * 获取容纳所传入坐标点的最小矩形左下和右上两点
 * @param {*} _path 
 */
const getPointsArea = (_path) => {
  let sw = {
    lat: _path[0].lat,
    lng: _path[0].lng,
  }
  let ne = {
    lat: _path[0].lat,
    lng: _path[0].lng,
  }

  for (let i in _path) {
    let tmp = _path[i]
    if (sw.lat > tmp.lat) {
      sw.lat = tmp.lat
    }
    if (sw.lng > tmp.lng) {
      sw.lng = tmp.lng
    }
  }

  for (let i in _path) {
    let tmp = _path[i]
    if (ne.lat < tmp.lat) {
      ne.lat = tmp.lat
    }
    if (ne.lng < tmp.lng) {
      ne.lng = tmp.lng
    }
  }

  return {
    sw,
    ne
  }
}

/**
 * 比较两坐标点是否相近
 * @param {*} p1 
 * @param {*} p2 
 * @param {*} threshold 精确度
 * @returns 
 */
const latlngEq = (p1, p2, threshold = 0.000001) => {
  let res = Math.abs(Number(p1.lat) - Number(p2.lat)) <= threshold
    && Math.abs(Number(p1.lng) - Number(p2.lng)) <= threshold

  return res
}

const exceptThisSymbols = ["e", "E", "+", "-", ".", ',', '@', ' '];

/**
 * 手机号验证
 * @param {*} phone
 * @returns 
 */
const checkPhone = (phone) => {
  if(!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(phone))) {
    return false; 
  }
  return true
}

/**
 * 解决移动端h5不兼容window.location.replace
 * @param {*} url 
 */
const locationReplace = (url) => {
  //eslint-disable-next-line
  if (history.replaceState && url.indexOf(location.origin) === 0) {
    //eslint-disable-next-line
    history.replaceState(null, document.title, url);//window.history.replaceState，其替换的url，必须和当前页面url是同源的，否则不生效
    //eslint-disable-next-line
    location.reload()
  } else {
    //eslint-disable-next-line
    location.replace(url);

  }
}

const yawThreshold = 15

const loadJavascript = (url) => {
  return new Promise((ok, err) => {
    let isReturned = false;

    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.onload = () => {
      // eslint-disable-next-line
      if (isReturned) {
        return;
      }
      isReturned = true
      ok()
    };
    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);

    setTimeout(() => {
      if (isReturned) {
        return
      }
      isReturned = true
      err('load javascript timeout!')
    }, 5000)

  })
}

// const fencesCategorys = [
//   '基础设施:交通设施:火车站', 
//   '基础设施:交通设施:飞机场', 
//   '基础设施:机场附属', 
//   '基础设施:机场附属:登机口',
//   '基础设施:机场附属:候机厅',
//   '基础设施:机场附属:出发',
//   '基础设施:机场附属:到达',
//   '基础设施:火车站附属',
//   '基础设施:火车站附属:候车厅',
//   '基础设施:火车站附属:出站口',
//   '基础设施:火车站附属:进站口',
// ]
const isFences = (category) => {
  return false;
  // return fencesCategorys.includes(category)
}

export {
  checkPhoneNumber,
  secondTohh_mm_ssStruct,
  secondTohh_mm_ss,
  dge,
  createPayUrl,
  ipay,
  stringToBase64,
  base64ToString,
  stringToBase64Safe,
  ForgeRouter,
  FeiShuJsSdk,
  debounce,
  clientMapZoom,
  copy,
  matchParameter,
  payType,
  WeChatWorkJsSdk,
  JnjJssdk,
  DingJsSdk,
  getPointsArea,
  latlngEq,
  exceptThisSymbols,
  checkPhone,
  vmIsIOS,
  locationReplace,
  yawThreshold,
  isDevice,
  loadJavascript,
  isFences,
  debounceUpgrades
}