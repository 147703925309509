import React from 'react'
import style from './payments.scss';
import { Tabs, Checkbox, Dialog, DatePicker, InfiniteScroll, Toast, Loading } from 'antd-mobile';
import { UpOutline } from 'antd-mobile-icons';

import moment from 'moment'

import webService from '../util/webService';
import images from '../util/images';

import NoPay from './../component/payments/noPay';
import Payed from './../component/payments/payed';

import { ipay, dge, payType } from './../util/utils'
import { oauthType } from './../util/config' 

import  {customerServiceTelephone, appId} from './../util/config'

import gc from '../util/globalConfiguration'

import { withTranslation } from "react-i18next";


class Payments extends React.Component {

    state = {
        currentState: 1,
        group: [
            {
                id: 1,
                title: this.props.t('payments.k001'),
                count: 0,
                key: 'nopay'
            },
            {
                id: 2,
                title: this.props.t('payments.k002'),
                count: 0,
                key: 'payed'
            }
        ],

        groupKey: 'nopay',

        payState: '',

        visible: false,

        noPayOrders: [],
        checkAll: false,
        sumPayAmount: 0,

        payed: {
            totalAmount: 0,
            rows: [],
            pageNum: 1,
            pageSize: 15,
        },
        hasMore: true,
        nowMonth: moment().toDate(),
        isData: null,
        isPayedData: null
        
    }

    t = this.props.t

     /**
   * 滚动文案
   */
   renderScrollContent = () => {
      return (
        <>
          {this.state.hasMore ? (
            <>
              <div className={style.loadingbox}>
                <div className={style.loading}>Loading</div>
                <Loading />
              </div>
            </>
          ) : (
            <div className={style.msg}>--- {this.t('invoice.k006')} ---</div>
          )}
        </>
      )
    }

    render() {
        return <div className={style.container}>
            <Tabs activeKey={this.state.groupKey} onChange={(key) => {
                let id = this.state.group.filter(a => a.key === key)[0].id;
                this.setState({
                    currentState: id,
                    groupKey: key
                })
            }}>
                {
                    this.state.group.map(item => {
                        return <Tabs.Tab title={item.title} key={item.key} />
                    })
                }
                
            </Tabs>
            {
                this.state.currentState === 1
                    ? (<div className={style.body}>
                        {
                            this.state.noPayOrders && this.state.noPayOrders.length > 0 && this.state.isData && this.state.noPayOrders.map((item,index) => {
                                return <NoPay t={this.props.t} key={item.id + index} order={item} history={this.props.history} onChange={(val) => {
                                    item.checked = val;
                                    let noPayOrders = this.state.noPayOrders;
                                    let checkAll = noPayOrders.length === noPayOrders.filter(a => a.checked === true).length;
                                    let checkedOrders = noPayOrders.filter(a => a.checked === true);
                                    let sumPayAmount = 0;
                                    for (let i in checkedOrders) {
                                        if (checkedOrders[i].payType === 1) {
                                          sumPayAmount += checkedOrders[i].enterpriseMoney
                                        } else if (checkedOrders[i].payType === 2) {
                                          sumPayAmount += checkedOrders[i].individualMoney
                                        } else {
                                          sumPayAmount += checkedOrders[i].prepaymentDownMoney
                                        }
                                    }
                                    
                                    this.setState({
                                        noPayOrders: this.state.noPayOrders,
                                        checkAll: checkAll,
                                        sumPayAmount: sumPayAmount.toFixed(2)
                                    })
        
                                }} />
                            })
                        }
                        {
                          !this.state.isData && this.state.isData !== null &&  (<div className={style.noneOrders}>
                            <div>
                                <img src={images.no_order} alt={this.t('payments.k005')} />
                            </div>
                            <div>{this.t('payments.k005')}</div>
                        </div>)
                        }
                       
                        {
                            this.state.noPayOrders && this.state.noPayOrders.length
                                ? (<div className={style.footbar}>
                                    <div>
                                        <Checkbox checked={this.state.checkAll} onChange={(val) => {
                                            let noPayOrders = this.state.noPayOrders;
                                            for (let i in noPayOrders) {
                                                noPayOrders[i].checked = val
                                            }
                                            let checkedOrders = noPayOrders.filter(a => a.checked === true);
                                            let sumPayAmount = 0;
                                            for (let i in checkedOrders) {
                                              if (checkedOrders[i].payType === 1) {
                                                sumPayAmount += checkedOrders[i].enterpriseMoney
                                              } else if (checkedOrders[i].payType === 2) {
                                                sumPayAmount += checkedOrders[i].individualMoney
                                              } else {
                                                sumPayAmount += checkedOrders[i].prepaymentDownMoney
                                              }
                                            }
                
                                            this.setState({
                                                noPayOrders: noPayOrders,
                                                checkAll: val,
                                                sumPayAmount: sumPayAmount.toFixed(2)
                                            })
                                        }}>{this.t('payments.k003')}</Checkbox>
                                    </div>
                                    <div>
                                    ￥{this.state.sumPayAmount}
                                    </div>
                                    <div onClick={this.pay}>
                                    {this.t('payments.k004')}
                                    </div>
                                </div>)
                                : <></>
                        }
                        
                    </div>)
                    : (<div className={style.bodyPayed}>
                        {
                            (() => {
                                if (this.state.payed.rows && this.state.payed.rows.length && this.state.isPayedData) {
                                    return this.state.payed.rows.map((item, index) => {
                                        return <Payed t={this.props.t} key={item.transactionNo + index} order={item} />
                                    })
                                }
                                if (!this.state.isPayedData && this.state.isPayedData !== null) {
                                  return <div className={style.noneOrders}>
                                        <div>
                                            <img src={images.personal_paydone1} alt={this.t('payments.k006')} />
                                        </div>
                                        <div>{this.t('payments.k006')}</div>
                                    </div>
                                }
                            })()
                            
                        }

                       {
                        
                        this.state.payed.rows && this.state.payed.rows.length > 0  ? <InfiniteScroll loadMore={this.loadMorePayedOrders} threshold={50} hasMore={this.state.hasMore}>
                          {this.renderScrollContent()}
                        </InfiniteScroll> : ''
                       } 

                        <div className={style.footbar}>
                            <div onClick={() => {
                                this.setState({
                                  visible: true
                                })
                            }}>{this.t('payments.k007')}{moment(this.state.nowMonth).format('YYYY-MM')} <UpOutline/></div>
                            <div>{this.t('payments.k008')}{this.state.payed.totalAmount ? this.state.payed.totalAmount.toFixed(2) : "0.00" + this.t('payments.k009')}</div>
                        </div>
                        <DatePicker cancelText={this.t('common.k001')} confirmText={this.t('common.k002')} defaultValue={this.state.nowMonth} visible={this.state.visible} onClose={()=> {
                          this.setState({
                            visible: false
                          })
                        }} precision="month" max={moment().toDate()} min={moment().add(-3, 'y').toDate()} onConfirm={d => {
                          if (d) {
                            this.setState({
                                nowMonth: d,
                                payed: {
                                    ...this.state.payed,
                                    pageNum: 1,
                                    rows: [],
                                    totalAmount: 0
                                }

                            }, () => {
                                this.loadPayedOrders(false);
                            })
                          }
                        }}></DatePicker>
                        
                        
                    </div>)
            }
            
        </div>
    }

    componentDidMount = async () => {

      // 如果是小程序则刷新待支付已支付列表
      document.addEventListener("visibilitychange", () => {
        if(document.hidden) {
          
        } else {
          if(dge() === 'wx-mini' && ['zhonghai', 'XCX032424'].includes(appId)){
            this.getPayState()
            this.setState({
              noPayOrders: [],
              checkAll: false
            })            
            this.loadPayedOrders(false);
            this.loadWaitPayOrders()
            console.log('小程序则刷新')
            console.log('noPayOrders')
            console.log(this.state.noPayOrders)
          }
        }
        
      });
      
      window.addEventListener("popstate", this.backFun, false);

      this.getPayState()
      

      this.loadWaitPayOrders()
      

      this.loadPayedOrders(false);

    }
    componentWillUnmount = () => {
      this.setState({
        visible: false
      })
      window.removeEventListener('popstate', this.backFun, false);
      localStorage.setItem('isPay', '');
      localStorage.setItem('payNo', '');
    }

    loadListPay = () => {
      localStorage.setItem('isPay', '');
      localStorage.setItem('payNo', '')
      if (dge() === 'jd-mini' || ['meituan'].includes(appId)) {
        this.setState({
          noPayOrders: [],
          checkAll: false
        }) 
      }
      this.loadPayedOrders(false);
      this.loadWaitPayOrders()
    }

    /**
     * 获取支付状态
     */
    getOrderPayState = async(params) => {
      return webService.order.getOrderPayState(params)
    }

    /**
     * 加载未支付数据
     */

    loadWaitPayOrders = () => {
      webService.order.getWaitPayOrders().then(d => {
        if (d.data.code === 0) {
            this.setState({
                noPayOrders: d.data.data.orders ? d.data.data.orders : []
            }, () => {
              if (this.state.noPayOrders && this.state.noPayOrders.length > 0) {
                this.setState({
                  isData: true
                })
              } else {
                this.setState({
                  isData: false
                })
              }
            })
        } else {
          this.setState({
            isData: false
          })
        }
      });
    }

    /**
     * 加载已支付数据
     * @param {*} isPaging 是否分页
     */
    loadPayedOrders = async (isPaging) => {
        let pageNum = isPaging ? this.state.payed.pageNum + 1 : 1;

        let d = await webService.order.getPayedOrders({
            dateDuration: moment(this.state.nowMonth).format('YYYY-MM'),
            pageNum: pageNum,
            pageSize: this.state.payed.pageSize
        });
        
        if (d.data.code === 0) {
            let rows = isPaging ? this.state.payed.rows : [];
            if (d.data.data.rows) {
              this.setState({
                  payed: {
                      ...this.state.payed,
                      totalAmount: d.data.data.totalAmount,
                      rows: [...rows, ...d.data.data.rows],
                      pageNum: d.data.data.pageNum
                  },
                  hasMore: d.data.data.rows.length < this.state.payed.pageSize ? false : true
              }, ()=> {
                if (this.state.payed.rows && this.state.payed.rows.length > 0) {
                  this.setState({
                    isPayedData: true
                  })
                } else {
                  this.setState({
                    isPayedData: false
                  })
                }
              })
            } else {
              this.setState({
                payed: {
                    ...this.state.payed,
                    // totalAmount: 0,
                    // rows: [],
                    pageNum: 0
                },
                hasMore: false
              })
            }
        } else {
          this.setState({
            isPayedData: false
          })
        }
    }

    backFun = () => {
      Dialog.clear()
    }

    /**
     * 加载更多
     */
    loadMorePayedOrders = async () => {
        await this.loadPayedOrders(true);
    }

    /**
     * 拉起支付
     */
    pay = async () => {
        let checkedOrders = this.state.noPayOrders.filter(a => a.checked === true);
        if (checkedOrders.length === 0) {
          Toast.show({
            content: '请选择支付订单',
            duration: 1000
          })
          return
        }
        try {
          Toast.show({
              icon: 'loading',
              content: this.t('payments.k014'),
              duration: 0,
              maskClickable: false
          })
          if ((oauthType === '6' && ['ziru', 'ziruziru'].includes(appId))
            || (oauthType === '8' && ['fanjia', 'meituan', 'tianjiu5'].includes(appId))) {
            let ids = checkedOrders.map(item => {
              return item.id
            })
            let orderNo = ids.join(',')
            let {data} = await webService.ziru.getPayUrl(orderNo)
            if (data.code === 0) {
              window.location.href = data.data
            } else {
              console.log('获取支付连接失败 ==> orderId=' + orderNo, data)
              console.log('appId', appId)
              Dialog.alert({
                  content: <div className={style.message}>
                    {
                      gc.process.isMeituan ? data.message : this.t('payments.k028', {appid: appId})
                    }
                    {
                      ![-1, 0, 1].includes(data.code) && gc.process.isMeituan ? <div className={style.code}>{data.code}</div> : <></>
                    }
                  </div>,
                  confirmText: this.t('common.k003'),
                  getContainer: () => document.getElementsByClassName('_2hk1zX3R-7zGrNgasL_4zK')[0],
                  onConfirm: async () => {
                    this.loadListPay();
                  }
              })
            }
            
          } else if (oauthType === '8' && appId === 'mstdt') {
            let ids = checkedOrders.map(item => {
              return item.id
            })
            let orderNo = ids.join(',')
            window.wx.miniProgram.navigateTo({url: `/pages/pay/index?orderNo=${orderNo}`})
          }  else {
            let orders = [];
            for (let i in checkedOrders) {
              if (checkedOrders[i].payType === 1) {
                orders.push({id:checkedOrders[i].id, amount: checkedOrders[i].enterpriseMoney, payType: checkedOrders[i].payType})
              } else if (checkedOrders[i].payType === 2) {
                orders.push({id:checkedOrders[i].id, amount: checkedOrders[i].individualMoney, payType: checkedOrders[i].payType})
              } else {
                orders.push({id:checkedOrders[i].id, amount: checkedOrders[i].prepaymentDownMoney, payType: checkedOrders[i].payType})
              }
                
            }
    
            if (orders && orders.length) {
                let url = window.location.href;
                let d = await webService.order.pay({orders: orders, redirectUri: url, paymentType: payType()});
                if (d.data.code === 0) {
                    if(['zhonghai', 'XCX032424'].includes(appId)) {
                      ipay(d.data.data, '');
                    } else if (oauthType === '1') {
                      ipay('', d.data.data.payUrl);
                    } else {
                      ipay(d.data.data, '/payments');
                    }
                    setTimeout(() => {
                      localStorage.setItem('isPay', true);
                      // payOrderid 京东使用的
                      localStorage.setItem('payNo', d.data.data.tranOrderNo ? d.data.data.tranOrderNo : d.data.data.payOrderid);
                      if (dge() === 'jd-mini') {
                        this.getPayState()
                      }
                    })
                } else {
                    Dialog.alert({
                        content: d.data.message,
                        confirmText: this.t('common.k003')
                    })
                }
            } else {
                Dialog.alert({
                    content: this.t('payments.k015'),
                    confirmText: this.t('common.k003')
                })
            }
          }
        } catch(e) {
          console.log('err', e)
        } finally {
          Toast.clear()
        }

        
    }
    /**
   *
   *加
   */
  accAdd = (num1,num2) => {
    let r1,r2,m;
    try {
        r1 = num1.toString().split('.')[1].length;
    } catch(e) {
        r1 = 0;
    }
    try {
        r2=num2.toString().split(".")[1].length;
    } catch(e) {
        r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    return Math.round( num1 * m + num2 * m ) / m;
  }

  /**
   * 获取支付状态
   */

  getPayState = async () => {
    // 支付标识
    let isPay = localStorage.getItem('isPay')

    // 支付单号
    let payNo = localStorage.getItem('payNo')

    let params = {
      PayOrderid: payNo
    }

    console.log('isPay', isPay)

    console.log('payNo', payNo)
    
    if (isPay) {

      Toast.show({
        icon: 'loading',
        content: this.t('payments.k014'),
        duration: 0
      })

      let result = await this.getOrderPayState(params)

      if (result.data.code === 0) {
        Toast.clear();
        this.setState({
          payState: result.data.data.payState
        }, () => {
          if (this.state.payState === 1) {
            Dialog.show({
              title: this.t('payments.k012'),
              content: (
                <>
                  <div className={style.confirmPay}>{this.t('payments.k010')}</div>
                </>
              ),
              actions: [
                [
                  {key: 'go_set', text: this.t('payments.k001'), 
                  onClick: () => {
                      this.loadListPay()
                  }}, 
                  {key: 'cancel', text: this.t('payments.k002'), bold: true, onClick: async () => {

                    let result = await this.getOrderPayState(params)
                    
                    if (result.data.code === 0) {
                      if (result.data.data.payState === 1) {
                        Dialog.show({
                          title: this.t('payments.k012'),
                          content: (
                            <>
                              <div className={style.service}>
                                {this.t('payments.k011')} <span>{customerServiceTelephone}</span>
                              </div>
                            </>
                          ),
                          actions: [
                            {
                              key: 'cancel',
                              text: this.t('payments.k013'),
                              onClick: () => {
                                this.loadListPay()
                              }
                            }
                          ],
                          closeOnAction: true
                        })
                      } 
                      else if (result.data.data.payState === 2) {
                        // 支付成功
                        this.setState({
                          groupKey: 'payed',
                          currentState: 2
                        })
                        this.loadListPay()
                      }
                    }
                  }}
              ]],
              closeOnAction: true
            })
          } else if (result.data.data.payState === 2) {
            // 支付成功
            this.setState({
              groupKey: 'payed',
              currentState: 2
            })
            this.loadListPay()
          }
        })
      } else {
        Toast.clear()
      }
    }
  }
}

export default withTranslation()(Payments);