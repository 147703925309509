import React from 'react';
import style from './qqMap.scss';

import moment from 'moment'

import { qqKey, amapUIKey } from './../../util/variable'
import { clientMapZoom, getPointsArea, yawThreshold, latlngEq } from './../../util/utils'
import images from './../../util/images'

import direction from './../../util/direction';
import { getRemainingSegment, totalDistance, getRemainingPath } from './../../util/pointOnSegmentByTurf'

import webService from './../../util/webService'
import gc from './../../util/globalConfiguration'
import loger from './../../util/loger'
// import { AMapTrack } from './../../util/aMapTrack'
// import { QQMapTrack } from './../../util/qqMapTrack'

let TMap = undefined
let AMap = undefined;

const AMAP_ZOOM_DEFAULT = 17.4;

class QQMap extends React.Component {

    state = {

    }

    loadTask = undefined;
    map = undefined;
    markerLayer = undefined;
    polygonLayer = undefined;
    watchLocationId = null;

    render() {
        const { children } = this.props
        return <div className={style.container}>
            <div id="qqmap_container" className={style.qqmap}>{children}</div>
        </div>
    }

    async componentDidMount() {
        // this.loadTask = this.QMap();
        // TMap = await this.loadTask;
        this.loadTask = this.LoadMap();
    }

    componentWillUnmount() {
        if (this.map) {
            this.map.destroy()
        }
        this.clearWatchPosition()
        try {
            if (this.at) {
                this.at.destroy()
            }
            if (this.qt) {
                this.qt.destroy()
            }
        } catch {}
    }

    LoadMap = () => {
        return new Promise(async (ok) => {
            let t1 = this.QMap()
            let t2 = this.GdMap()

            TMap = await t1;
            AMap = await t2;

            ok()
        })
        
    }

    QMap = () => {
        return new Promise((resolve, reject) => {
            if (TMap) {
                resolve(TMap);
                return
            }

            let script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://map.qq.com/api/gljs?v=1.exp&libraries=geometry&key=' + qqKey;
            script.onerror = reject;

            script.onload = () => {
                resolve(window.TMap);
            };
            document.getElementsByTagName('head')[0].appendChild(script);
        });
    }

    GdMap = () => {
        return new Promise((resolve, reject) => {
            if (AMap || window.AMap) {
                resolve(AMap || window.AMap);
                return
            }


            let script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://webapi.amap.com/maps?v=1.4.15&key=' + amapUIKey;
            script.onerror = reject;

            script.onload = () => {
                resolve(window.AMap);
            };
            document.getElementsByTagName('head')[0].appendChild(script);
        });
    }

    /**
     * 初始化地图
     */
    initMap = async (geo, startEndPop = false) => {
        console.log('加载腾讯地图')
        if (this.loadTask) {
            await this.loadTask;
        }

        let clientMapZooms = clientMapZoom()
        let center = undefined;
        let initParams = {
            zoom: AMAP_ZOOM_DEFAULT,
            showControl: false,
            minZoom: clientMapZooms[0],
            maxZoom: clientMapZooms[1],
            mapZoomType: TMap.constants.MAP_ZOOM_TYPE.CENTER,
            mapStyleId: 'style2',
            viewMode: '2D'
        }
        if (geo && geo.lat) {
            center = new TMap.LatLng(geo.lat, geo.lon);
            initParams.center = center
        }
        // 地图
        this.map = new TMap.Map(document.getElementById('qqmap_container'), initParams);
        // 设置地图楼房无3d效果
        this.map.setBaseMap({
            type: 'vector',
            features: ['base', 'building2d', 'point', 'label', 'arrow']
        })
        window.openQQMapDebug = () => {
            this.map.on("click", (evt) => {
                let lat = evt.latLng.getLat().toFixed(6);
                let lng = evt.latLng.getLng().toFixed(6);
                console.log("您点击的的坐标是：" + lat + "," + lng);

                if (window.updatePostion && window.openAutoUpdatePostion) {
                    window.updatePostion({
                        lat: lat,
                        lng: lng
                    })
                }
            })
            
        }
        window.getMapObject = () => {
          return this.map
        }
        window.getMapCanvasMarkerManager = () => {
            let canvas = document.body.appendChild(this.map.renderEngine.markerManager.canvas)
            let dataURL = canvas.toDataURL('image/png');
            console.log('dataURL', dataURL)
            let downloadLink = document.createElement('a');
            downloadLink.href = dataURL;
            downloadLink.download = 'canvas-image.png';
            downloadLink.click();
        }
        // 获取renderEngine.glyphManager.canvas
        window.getMapCanvasGlyphManager = () => {
            let canvas = document.body.appendChild(this.map.renderEngine.glyphManager.canvas)
            let dataURL = canvas.toDataURL('image/png');
            console.log('dataURL', dataURL)
            let downloadLink = document.createElement('a');
            downloadLink.href = dataURL;
            downloadLink.download = 'canvas-image.png';
            downloadLink.click();
        }
        window.map_drawManager_renderLayers = () => {
            return this.map.drawManager.renderLayers
        } 
        console.log('加载地图成功')
        this.markerRealLocation = new TMap.MultiMarker({
            map: this.map,
            styles: {
                current_point: new TMap.MarkerStyle({
                    width: 36,
                    height: 36,
                    anchor: {
                        x: 18,
                        y: 18
                    },
                    src: images.orientation
                })
            }
        })
        // 详情图层小车
        this.markerOrderDetails = new TMap.MultiMarker({
            map: this.map,
            styles: {
                begin_icon: new TMap.MarkerStyle({
                    width: 28,
                    height: 28,
                    anchor: {
                        x: 12.5,
                        y: 30
                    },
                    src: images.beginAddr,
                    direction: 'bottom',
                    size: 12,
                    color: '#42a737',
                    strokeColor: '#ffffff',
                    strokeWidth: 2
                }),
                end_icon: new TMap.MarkerStyle({
                    width: 28,
                    height: 28,
                    anchor: {
                        x: 12.5,
                        y: 30
                    },
                    src: images.endAddr,
                    direction: 'bottom',
                    size: 12,
                    color: '#42a737',
                    strokeColor: '#ffffff',
                    strokeWidth: 2
                }),
                car_down: new TMap.MarkerStyle({
                    width: 40,
                    height: 40,
                    anchor: {
                        x: 20,
                        y: 20,
                    },
                    faceTo: 'map',
                    rotate: 180,
                    src: images.carMove
                })
            }
        })
        // 点图层
        this.markerLayer = new TMap.MultiMarker({
            map: this.map,
            styles: {
                letterTemplate: new TMap.MarkerStyle({
                    width: 14,
                    height: 14,
                    anchor: {
                        x: 7.5,
                        y: 6
                    },
                    src: images.mr_point,
                    direction: 'bottom',
                    size: 14,
                    color: 'rgba(0, 0, 0, 0)',
                    opacity: 0,
                    // strokeColor: '#fffff0',
                    // strokeWidth: 2
                }),
                point: new TMap.MarkerStyle({
                    width: 14,
                    height: 14,
                    anchor: {
                        x: 7.5,
                        y: 6
                    },
                    src: images.mr_point,
                    direction: 'bottom',
                    size: 14,
                    color: gc.process.isMeituan ? '#000000' : '#42a737',
                    strokeColor: '#ffffff',
                    strokeWidth: 2
                }),
                pointRed: new TMap.MarkerStyle({
                    width: 14,
                    height: 14,
                    anchor: {
                        x: 7,
                        y: 7
                    },
                    src: images.pointRed,
                    direction: 'bottom',
                    size: 14,
                    color: '#F34747',
                    strokeColor: '#ffffff',
                    strokeWidth: 2
                }),
                pointYellow: new TMap.MarkerStyle({
                    width: 14,
                    height: 14,
                    anchor: {
                        x: 7,
                        y: 7
                    },
                    src: images.pointYellow,
                    direction: 'bottom',
                    size: 14,
                    color: '#F5A623',
                    strokeColor: '#ffffff',
                    strokeWidth: 2
                }),
                begin_icon: new TMap.MarkerStyle({
                    width: 28,
                    height: 28,
                    anchor: {
                        x: 12.5,
                        y: 30
                    },
                    src: images.beginAddr,
                    direction: 'bottom',
                    size: 12,
                    color: '#42a737',
                    strokeColor: '#ffffff',
                    strokeWidth: 2
                }),
                end_icon: new TMap.MarkerStyle({
                    width: 28,
                    height: 28,
                    anchor: {
                        x: 12.5,
                        y: 30
                    },
                    src: images.endAddr,
                    direction: 'bottom',
                    size: 12,
                    color: '#42a737',
                    strokeColor: '#ffffff',
                    strokeWidth: 2
                }),
                user_icon: new TMap.MarkerStyle({
                    width: 30,
                    height: 30,
                    anchor: {
                        x: 15,
                        y: 30
                    },
                    src: images.userIcon,
                    direction: 'bottom',
                    size: 12,
                    color: '#42a737',
                    strokeColor: '#ffffff',
                    strokeWidth: 2
                }),
                perEnd_icon: new TMap.MarkerStyle({
                    width: 30,
                    height: 30,
                    anchor: {
                        x: 15,
                        y: 30
                    },
                    src: images.perEndIcon,
                    direction: 'bottom',
                    size: 12,
                    color: '#42a737',
                    strokeColor: '#ffffff',
                    strokeWidth: 2
                }),
                driver_icon: new TMap.MarkerStyle({
                    width: 30,
                    height: 30,
                    anchor: {
                        x: 15,
                        y: 30
                    },
                    src: images.driverIcon,
                    direction: 'bottom',
                    size: 12,
                    color: '#42a737',
                    strokeColor: '#ffffff',
                    strokeWidth: 2
                }),
                car_down: new TMap.MarkerStyle({
                    width: 40,
                    height: 40,
                    anchor: {
                        x: 20,
                        y: 20,
                    },
                    faceTo: 'map',
                    rotate: 180,
                    src: images.carMove,
                })
            },
            geometries: [
                {
                    id: 'letterTemplate_0',
                    styleId: 'letterTemplate',
                    position: new TMap.LatLng(0, 0),
                    content: 'ABCDEFGHIJKLMNOPabcdefghijklml0123456789'
                }
            ]
        })
        this.charteredBusMarker = new TMap.MultiMarker({
            map: this.map,
            styles: {
                begin_icon: new TMap.MarkerStyle({
                    width: 28,
                    height: 28,
                    anchor: {
                        x: 12.5,
                        y: 30
                    },
                    src: images.beginAddr,
                    direction: 'bottom',
                    size: 12,
                    color: '#42a737',
                    strokeColor: '#ffffff',
                    strokeWidth: 2
                }),
                end_icon: new TMap.MarkerStyle({
                    width: 28,
                    height: 28,
                    anchor: {
                        x: 12.5,
                        y: 30
                    },
                    src: images.endAddr,
                    direction: 'bottom',
                    size: 12,
                    color: '#42a737',
                    strokeColor: '#ffffff',
                    strokeWidth: 2
                })
            }
        })
        this.markerLayer.on("click", (evt) => {
            if (this.props.onMarkerClick) {
                this.props.onMarkerClick(evt.geometry)
            }

        })

        // 多边形图层
        this.polygonLayer = new TMap.MultiPolygon({
            id: 'polygon-layer',
            map: this.map,
            //多边形样式
            styles: {
                polygon: new TMap.PolygonStyle({
                    color: gc.process.isMeituan ? 'rgba(0, 171, 25, 0.2)' : 'rgba(173, 212, 226, 0.5)',
                    borderColor: gc.process.isMeituan ? 'rgba(0, 171, 25, 1)' :'rgba(47, 125, 205, 0.9)',
                    borderWidth: 2,
                    showBorder: true,
                    borderDashArray: [5, 5]
                })
            },
            //多边形数据
            geometries: []
        });

        // 折现图层
        this.polylineLayer = new TMap.MultiPolyline({
            id: 'polyline-layer',
            map: this.map,
            styles: {
                style_driving: new TMap.PolylineStyle({
                    color: '#1bac2e',
                    width: 6,
                    borderWidth: 2,
                    borderColor: '#ffffff',
                    lineCap: 'round',
                    showArrow: true,
                    arrowOptions: {
                        width: 5,
                        height: 5,
                        space: 20
                    }
                }),
                style_driving_hubOfTraffic: new TMap.PolylineStyle({
                    color: '#1bac2e',
                    width: 6,
                    borderWidth: 2,
                    borderColor: '#ffffff',
                    lineCap: 'round',
                    dashArray: [10, 10],
                    showArrow: false,
                    arrowOptions: {
                        width: 5,
                        height: 5,
                        space: 20
                    }
                })
            },
            geometries: []
        })

        // this.map.on("zoom_changed", (evt) => {
        //     var zoomLevel = this.map.getZoom();
        //     console.log('zoomLevel', zoomLevel)
        // })

        // 修改起止位置弹框
        if (startEndPop) {
            this.endInfo = new TMap.InfoWindow({
                map: this.map,
                position: new TMap.LatLng(0, 0),
                offset: { x: 0, y: -30 },
                // enableCustom: true
            });

            this.startInfo = new TMap.InfoWindow({
                map: this.map,
                position: new TMap.LatLng(0, 0),
                offset: { x: 0, y: -30 },
                // enableCustom: true
            });
            document.getElementById('qqmap_container').getElementsByTagName('div')[0].children[1].remove()
        } else {
            document.getElementById('qqmap_container').getElementsByTagName('div')[0].remove();
        }
    }

    addPop(pops = []) {
        pops.forEach(item => {
            this[item.name].setPosition(new TMap.LatLng(Number(item.info.lat), Number(item.info.lng)))
            this[item.name].setContent(item.content)
        })
    }

    getMap() {
        return this.map;
    }
    // map对象常用方法
    setZoom(zoom) {
        this.map.setZoom(zoom)
    }

    // 获取地图缩放级别
    getZoom() {
        return this.map.getZoom()
    }

    // 是否支持拖拽
    setDraggable(value) {
        this.map.setDraggable(value)
    }

    // 是否支持滚轮缩放
    setScrollable(value) {
        this.map.setScrollable(value)
    }

    // 将地图中心平滑移动到指定的经纬度坐标
    panTo(geo) {
        this.map.panTo(new TMap.LatLng(geo[1], geo[0]), { duration: 10 })
    }

    // 是否支持双击缩放
    setDoubleClickZoom(value) {
        this.map.setDoubleClickZoom(value)
    }

    // 平滑缩放到指定级别
    zoomTo(value) {
        this.map.zoomTo(value)
    }

    // 获取地图中心
    getCenter() {
        return this.map.getCenter()
    }

    // 监听marker点击事件
    on(eventName, fn) {
        this.map.on(eventName, fn)
    }

    clearPoint() {
        this.markerLayer.setGeometries([])
    }

    removePoint(pid) {
        if (this.markerRealLocation && pid) {
            this.markerRealLocation.remove([pid])
        }
    }

    /**
     * 展示当前定位点
     * @param {*} currentGeo 
     */
    showCurrentPoint(currentGeo) {
        this.removePoint('current_point')
        this.markerRealLocation.add([{
            id: 'current_point',
            styleId: 'current_point',
            position: new TMap.LatLng(currentGeo.lat, currentGeo.lon),
        }])
    }

    // 推荐上车地点
    recommendMarkers = [];
    currentrecommendMarkerIndex = 0;

    /**
     * 删除推荐上车地点
     */
    removeRecommendMarkers() {
        this.markerLayer.remove(['rm0', 'rm1', 'rm2'])
        this.recommendMarkers = []
    }

    /**
     * 添加推荐上车地点
     * @param {*} list 
     */
    addRecommendMarkers(list) {
        this.removeRecommendMarkers()
        this.recommendMarkers = list

        this.markerLayer.add(this.recommendMarkers.map((item, i) => {
            return {
                id: 'rm' + i,
                styleId: 'point',
                position: new TMap.LatLng(item.location.lat, item.location.lng),
                content: item.title
            }
        }))

        
    }

    /**
     * 隐藏推荐上车地点文字描述
     * @param {*} index 
     */
    hideRecommendMarkerLabel(index) {
        this.currentrecommendMarkerIndex = index;
        let _list = this.recommendMarkers.map((item, i) => {
            return {
                id: 'rm' + i,
                styleId: 'point',
                position: new TMap.LatLng(item.location.lat, item.location.lng),
                content: i !== index ? item.title : ''
            }
        })
        if (this.recommendMarkers.length > index) {
            try {
                // 修改点标记
                this.markerLayer.updateGeometries(_list)
            } catch (e) {
                console.log(e)
            }
        }
    }

    /**
     * 显示推荐上车地点文字描述
     * @param {*} index 
     */
    showRecommendMarkerLabel(index) {
        let current = this.recommendMarkers[index]
        if (current) {
            this.markerLayer.updateGeometries([current])
        }
    }

    /**
     * 隐藏推荐上车地点
     */
    hideRecommendMarker() {
        let geo = this.markerLayer.getGeometryById('rm0')
        if (geo) {
            this.markerLayer.remove(['rm0', 'rm1', 'rm2'])
        }
    }

    /**
     * 显示推荐上车地点(如果有)
     */
    showRecommendMarker() {
        if (this.recommendMarkers && this.recommendMarkers.length) {
            let geo = this.markerLayer.getGeometryById('rm0')

            if (geo) {
                // 什么都不做
                return
            } else {
                this.markerLayer.add(this.recommendMarkers.map((item, i) => {
                    return {
                        id: 'rm' + i,
                        styleId: 'point',
                        position: new TMap.LatLng(item.location.lat, item.location.lng),
                        content: this.currentrecommendMarkerIndex === i ? '' : item.title
                    }
                }))
            }
        }
    }

    fencePoints = [];
    /**
     * 清除围栏
     */
    removeFence() {
        if (this.polygonLayer && this.polygonLayer._rawGeometries && this.polygonLayer._rawGeometries[0]) {
            this.polygonLayer.setGeometries([])
            this.map.setZoom(AMAP_ZOOM_DEFAULT)
        }
        this.fencePoints = [];
    }

    /**
     * 绘制围栏
     * @param {*} path 
     */
    addFence(path) {
        let _path = path.split(';').map(a => {
            let _tmp = a.split(',')
            return {
                lat: Number(_tmp[0]),
                lng: Number(_tmp[1])
            }
        })


        this.removeFence();
        this.removeRecommendMarkers();

        this.fencePoints = _path.map(a => {
            return new TMap.LatLng(a.lat, a.lng)
        })

        this.polygonLayer.add([
            {
                id: 'gon0',
                styleId: 'polygon',
                paths: this.fencePoints
            }
        ])

        // this.map.setDraggable(false)
        // this.map.setScrollable(false)


        /**
         * 1、fitBounds：设置地图显示范围，根据坐标自适应调整，把指定的地理范围完整显示在地图可视范围内
         * 2、LatLngBounds：描述了一个矩形的地理坐标范围，它由矩形西南角(左下) 和 东北角(右上)坐标构成。
         */
        let { sw, ne } = getPointsArea(_path)
        this.map.fitBounds(new TMap.LatLngBounds(new TMap.LatLng(sw.lat, sw.lng), new TMap.LatLng(ne.lat, ne.lng)), { padding: 15 })
    }

    /**
     * 判断点是否在围栏内
     * @param {*} point 
     */
    isPointInFence(point) {
        return this.fencePoints.length && TMap.geometry.isPointInPolygon(point, this.fencePoints)
    }

    /**
     * 清理导航线条
     */
    clearDriving() {
        try {
            this.markerLayer.stopMove()
        } catch (exp) {
            console.error('阻止动画失败', exp)
        }
        this.markerLayer.setGeometries([])
        this.polylineLayer.setGeometries([])
    }

    /**
     * 清理包车
     */
    clearDrivingBus() {
        try {
            this.charteredBusMarker.stopMove()
        } catch (exp) {
            console.error('阻止动画失败', exp)
        }
        this.charteredBusMarker.setGeometries([])
    }

    /**
     * 增加调式轨迹采集点
     * @param {*} item1 
     * @param {*} item2 
     */
    addMapDevPoint(item1, item2) {
        this.markerLayer.add([
            {
                id: 'devPoint1' + new Date().getTime(),
                styleId: 'pointRed',
                position: new TMap.LatLng(item1.lat, item1.lng)
            },
            {
                id: 'devPoint2' + new Date().getTime(),
                styleId: 'pointYellow',
                position: new TMap.LatLng(item2.lat, item2.lng)
            }
        ])
    }

    /**
     * 增加调式轨迹起终点
     * @param {*} begin 
     * @param {*} end 
     */
    addMapDev(begin, end) {
        console.log(begin, end)
        this.markerLayer.add([
            {
                id: 'devPoint_begin',
                styleId: 'begin_icon',
                position: new TMap.LatLng(begin.lat, begin.lng),
                content: '行程起点'
            },
            {
                id: 'devPoint_end',
                styleId: 'end_icon',
                position: new TMap.LatLng(end.lat, end.lng),
                content: '行程终点'
            }
        ])
    }

    /**
     * 绘制两点间导航路径
     * @param {*} begin 
     * @param {*} end 
     * @param {*} serviceType 
     * @param {*} pathways 途径点 
     */
    async addDriving(begin, end, serviceType, pathways, positionBottom) {
        direction.Enable = true
        let res = await direction.driving(begin, end, true, pathways)
        if (res && res.code !== 0) {
            return
        }

        let arr = res.polyline;
        if (serviceType !== 20) {
            this.drawDriving(arr, 'begin_icon', 'end_icon', begin.locationName, end.locationName, positionBottom)
        } else {
            this.drawDrivingBus(arr, 'begin_icon', 'end_icon', begin.locationName, end.locationName, positionBottom)
        }

        if (pathways && pathways.length) {
            this.markerLayer.add(
                pathways.map((item, index) => {
                    return {
                        id: 'pathway' + index,
                        styleId: 'perEnd_icon',
                        position: new TMap.LatLng(item.lat, item.lng),
                        content: item.name
                    }
                }))
        }
        return res
    }

    /**
     * 绘制起点到终点导航路线
     * @param {*} paths 
     * @param {*} beginStyleId 
     * @param {*} endStyleId 
     * @param {*} beginLocationName 
     * @param {*} endLocationName 
     */
    drawDriving(paths, beginStyleId, endStyleId, beginLocationName, endLocationName, positionBottom = 65) {
        this.clearDriving();
        let _path = []
        for (let i = 0; i < paths.length; i += 2) {
            _path.push({
                lat: paths[i],
                lng: paths[i + 1]
            })
        }

        let polylinePoints = _path.map(a => new TMap.LatLng(a.lat, a.lng))

        this.markerLayer.add([
            {
                id: 'rm_begin',
                styleId: beginStyleId,
                position: polylinePoints[0],
                content: beginLocationName
            },
            {
                id: 'rm_end',
                styleId: endStyleId,
                position: polylinePoints[polylinePoints.length - 1],
                content: endLocationName
            }
        ])
        this.polylineLayer.add([
            {
                id: 'pl_0',
                styleId: 'style_driving',
                paths: polylinePoints
            }
        ])
        let { sw, ne } = getPointsArea(_path)
        let bounds = new TMap.LatLngBounds(new TMap.LatLng(sw.lat, sw.lng), new TMap.LatLng(ne.lat, ne.lng))
        // bounds.extend(new TMap.LatLng(begin.lat, begin.lng))
        // bounds.extend(new TMap.LatLng(end.lat, end.lng))
        if (this.map) {
            this.map.fitBounds(bounds, {
                padding: {
                    top: 45,
                    right: 30,
                    bottom: positionBottom,
                    left: 30
                }
            })
        }

    }

    /**
     * 包车绘制起点到终点
     * @param {*} paths 
     * @param {*} beginStyleId 
     * @param {*} endStyleId 
     * @param {*} beginLocationName 
     * @param {*} endLocationName 
     */
    drawDrivingBus(paths, beginStyleId, endStyleId, beginLocationName, endLocationName, positionBottom) {
        this.clearDrivingBus()
        let _path = []
        for (let i = 0; i < paths.length; i += 2) {
            _path.push({
                lat: paths[i],
                lng: paths[i + 1]
            })
        }

        let polylinePoints = _path.map(a => new TMap.LatLng(a.lat, a.lng))
        // if (this.map && this.charteredBusMarker) {
            this.charteredBusMarker.add([
                {
                    id: 'begin_icon',
                    styleId: beginStyleId,
                    position: polylinePoints[0],
                    content: beginLocationName
                },
                {
                    id: 'end_icon',
                    styleId: endStyleId,
                    position: polylinePoints[polylinePoints.length - 1],
                    content: endLocationName
                }
            ])
        // }
        let { sw, ne } = getPointsArea(_path)
        let bounds = new TMap.LatLngBounds(new TMap.LatLng(sw.lat, sw.lng), new TMap.LatLng(ne.lat, ne.lng))
        // bounds.extend(new TMap.LatLng(begin.lat, begin.lng))
        // bounds.extend(new TMap.LatLng(end.lat, end.lng))
        if (this.map) {
            this.map.fitBounds(bounds, {
                padding: {
                    top: 50,
                    right: 70,
                    bottom: positionBottom,
                    left: 50
                }
            })
        }

    }

    /**
     * 绘制司机和乘客图标
     * @param {*} passenger 
     * @param {*} driver 
     */
    drawdriverAndPassengerPosition(passenger, driver) {
        this.clearDriving();
        this.markerLayer.add([
            {
                id: 'rm_passenger',
                styleId: 'user_icon',
                position: new TMap.LatLng(passenger.lat, passenger.lng)
            },
            {
                id: 'rm_driver',
                //styleId: 'driver_icon',
                styleId: 'car_down',
                position: new TMap.LatLng(driver.lat, driver.lng),
            }
        ])
        let _path = [
            {
                lat: passenger.lat,
                lng: passenger.lng
            },
            {
                lat: driver.lat,
                lng: driver.lng
            }
        ]
        let { sw, ne } = getPointsArea(_path)
        let bounds = new TMap.LatLngBounds(new TMap.LatLng(sw.lat, sw.lng), new TMap.LatLng(ne.lat, ne.lng))
        this.map.fitBounds(bounds, {
            padding: {
                top: 45,
                right: 30,
                bottom: 65,
                left: 30
            }
        })
    }

    /**
     * 添加起点图标（进行中页面用到）
     * @param {*} begin 
     */
    addBeginMarker(begin) {
        this.markerLayer.setGeometries([])
        this.markerLayer.add([
            {
                id: 'rm_begin',
                styleId: 'begin_icon',
                position: new TMap.LatLng(begin.lat, begin.lng),
                content: begin?.locationName ?? ''
            }
        ])

        this.map.setZoom(AMAP_ZOOM_DEFAULT)
        this.map.panTo(new TMap.LatLng(begin.lat, begin.lng), { duration: 10 })
    }


    /**
     * 进行中路径缓存
     */
    runingPaths = {
        path: [],
        distance: 0,
        duration: 0,
        isPickup: true,
        end: undefined,
        // 最新动画播放轨迹位置
        passedLatLngs: undefined,
        // 偏航次数
        countYaw: 0,
        // 坐标变化记录（每次偏航后清空）
        driverPositions: [],
        // 终点交通枢纽围栏
        hub: {},

        // 判断终点是否发生变化
        isSameEnd(_end) {
            return (this.end !== undefined && this.end.lat === _end.lat && this.end.lng === _end.lng)
        },

        isInStation: false,

        clear() {
            this.path = [];
            this.distance = 0;
            this.duration = 0;
            this.isPickup = true;
            this.passedLatLngs = undefined;
            this.end = undefined;
            this.countYaw = 0;
            this.driverPositions = [];
            this.hub = {};
            this.isInStation = false;
        }
    }

    /**
     * 启动小车轨迹动画
     * @param {*} isPickup 
     */
    moveAlongStart(isPickup) {
        let endStyleId = isPickup ? 'user_icon' : 'end_icon'
        let paths = this.runingPaths.path;
        let _path = []
        for (let i = 0; i < paths.length; i += 2) {
            _path.push({
                lat: paths[i],
                lng: paths[i + 1]
            })
        }
        let polylinePoints = _path.map(a => new TMap.LatLng(a.lat, a.lng))

        let car = this.markerLayer.getGeometryById('car')

        // let rotate = TMap.geometry.computeHeading(polylinePoints[0], polylinePoints[1], false)
        // if (rotate < 0) {
        //     rotate = rotate * -1
        // }
        // console.log(rotate)
        // this.markerLayer.styles.car_down.rotate = rotate
        this.markerLayer.add(
            [
                {
                    id: 'car',
                    styleId: 'car_down',
                    position: polylinePoints[0]
                },
                {
                    id: 'rm_end',
                    styleId: endStyleId,
                    position: polylinePoints[polylinePoints.length - 1],
                    content: ''
                }
            ]
        )
        this.markerLayer.moveAlong(
            {
                "car": {
                    path: polylinePoints,
                    speed: 0.1
                }
            },
            {
                autoRotation: true
            }
        )
        setTimeout(() => {
            this.markerLayer.pauseMove()
        }, 200);
        this.runingPaths.passedLatLngs = polylinePoints[0]

        // 删除不需要的点
        let reserveMarker = []
        if (isPickup) {
            // 接时只保留乘客，车辆，定位
            reserveMarker = ['car', 'rm_end', 'dingwei', 'per_end']
        } else {
            // 送时只保留终点，车辆
            reserveMarker = ['car', 'rm_end']
        }

        let allMarker = this.markerLayer.getGeometries()
        let delMarker = allMarker.filter(a => !reserveMarker.includes(a.id))
        if (delMarker && delMarker.length) {
            this.markerLayer.remove(delMarker.map(a => a.id))
        }


        let { sw, ne } = getPointsArea(polylinePoints)
        let bounds = new TMap.LatLngBounds(new TMap.LatLng(sw.lat, sw.lng), new TMap.LatLng(ne.lat, ne.lng))
        this.map.fitBounds(bounds, {
            padding: {
                top: 245,
                right: 30,
                bottom: 159,
                left: 30
            }
        })

        if (car && car.id === 'car') {
            // 首次调用此方法，则car必为空，用此判断避免moving事件多次挂载
            return
        }
        this.markerLayer.on('moving', e => {
            if (e && e.car && e.car.passedLatLngs && e.car.passedLatLngs.length) {
                let currentCarPosition = {
                    lat: e.car.passedLatLngs[e.car.passedLatLngs.length - 1].lat,
                    lng: e.car.passedLatLngs[e.car.passedLatLngs.length - 1].lng
                }

                this.runingPaths.passedLatLngs = currentCarPosition;
                if (this.runingPaths.path && this.runingPaths.path.length) {
                    let polyline = this.runingPaths.path;
                    // let result = getRemainingSegment(polyline, [currentCarPosition.lat, currentCarPosition.lng], yawThreshold)
                    let result = getRemainingPath(polyline, [currentCarPosition.lat, currentCarPosition.lng])

                    if (result.code === 0) {
                        this.runingPaths.path = result.path;
                        polyline = result.path;
                        let _polyline = [];
                        for (let i = 0; i < polyline.length; i += 2) {
                            _polyline.push(new TMap.LatLng(polyline[i], polyline[i + 1]));
                        }

                        this.polylineLayer.updateGeometries([
                            {
                                id: 'pl_0',
                                styleId: 'style_driving',
                                paths: _polyline
                            }
                        ])

                        // 针对修改终点情况
                        this.markerLayer.updateGeometries(
                            [
                                {
                                    id: 'rm_end',
                                    styleId: endStyleId,
                                    position: _polyline[_polyline.length - 1],
                                    content: ''
                                }
                            ]
                        )
                    }
                }
            }
        })
    }

    /**
     * 更新小车动画
     * @param {*} currentPoint 
     * @param {*} timediff
     */
    moveAlongUpdate(currentPoint, timediff) {
        this.markerLayer.pauseMove();
        let polyline = this.runingPaths.path;

        // let currentCarPosition = this.runingPaths.passedLatLngs;
        // let result = getRemainingSegment(polyline, [currentCarPosition.lat, currentCarPosition.lng], yawThreshold)
        // let result = getRemainingPath(polyline, [currentCarPosition.lat, currentCarPosition.lng])
        let result = {
            code: 0,
            path: polyline
        }
        if (result.code === 0) {
            let res = getRemainingSegment(result.path, [Number(currentPoint.lat), Number(currentPoint.lng)], yawThreshold)
            if (res.code === 0) {
                polyline = res.completePath;
                let _polyline = [];
                for (let i = 0; i < polyline.length; i += 2) {
                    _polyline.push(new TMap.LatLng(polyline[i], polyline[i + 1]));
                }

                let carSpeed = totalDistance(polyline)
                carSpeed = carSpeed * 3.6 / timediff
                if (carSpeed <= 0) {
                    return
                }

                this.markerLayer.updateGeometries([
                    {
                        id: 'car',
                        styleId: 'car_down',
                        position: _polyline[0],
                    }
                ])

                this.markerLayer.moveAlong(
                    {
                        car: {
                            path: _polyline,
                            speed: carSpeed
                        }
                    },
                    {
                        autoRotation: true
                    }
                )

                polyline = result.path;
                _polyline = [];
                for (let i = 0; i < polyline.length; i += 2) {
                    _polyline.push(new TMap.LatLng(polyline[i], polyline[i + 1]));
                }

                let { sw, ne } = getPointsArea(_polyline)
                let bounds = new TMap.LatLngBounds(new TMap.LatLng(sw.lat, sw.lng), new TMap.LatLng(ne.lat, ne.lng))
                if (window.vm) {
                    return
                }
                this.map.fitBounds(bounds, {
                    padding: {
                        top: 245,
                        right: 30,
                        bottom: 159,
                        left: 30
                    }
                })
            }
        }

    }

    /**
     * 厂站内导航
     * @param {*} begin 
     * @param {*} end 
     * @param {*} isPickup 
     */
    hubOfTrafficDriving(begin, end, isPickup) {
        let endStyleId = isPickup ? 'user_icon' : 'end_icon'
        let _begin = new TMap.LatLng(begin.lat, begin.lng)
        let _end = new TMap.LatLng(end.lat, end.lng)

        this.clearDriving()
        this.polylineLayer.add([
            {
                id: 'pl_0',
                styleId: 'style_driving_hubOfTraffic',
                paths: [_begin, _end]
            }
        ])
        this.markerLayer.add(
            [
                {
                    id: 'car',
                    styleId: 'car_down',
                    position: _begin
                },
                {
                    id: 'rm_end',
                    styleId: endStyleId,
                    position: _end,
                    content: ''
                }
            ]
        )
        this.markerLayer.moveAlong(
            {
                "car": {
                    path: [_begin, _end],
                    speed: 10
                }
            },
            {
                autoRotation: true
            }
        )

        this.removeFence();
        this.removeRecommendMarkers();

        console.log(this.runingPaths.hub)
        this.polygonLayer.add([
            {
                id: 'gon0',
                styleId: 'polygon',
                paths: this.runingPaths.hub.polygon
            }
        ])
    }

    /**
     * 判断点是否在交通枢纽范围内
     * @param {*} lat 
     * @param {*} lon 
     * @returns 
     */
    async isHubOfTraffic(lat, lon) {
        let fencesRes = await webService.map.gethitFences(lat, lon, moment().format('X'), moment().format('X'));
        if (fencesRes.status === 200)
        {
            let hit_hub_of_traffic = fencesRes.data && fencesRes.data.data && fencesRes.data.data.detail ? fencesRes.data.data.detail.hit_hub_of_traffic : 0
            if (hit_hub_of_traffic === 1)
            {
                let fencesName = fencesRes.data.data.detail.data.name
                let polygon = fencesRes.data.data.detail.data.polygon.split(';')
                polygon = polygon.map(a => {
                    let _tmp = a.split(',')
                    return new TMap.LatLng(_tmp[0], _tmp[1])
                })
                return {
                    hit: hit_hub_of_traffic,
                    fencesName: fencesName,
                    polygon: polygon
                }
            }
        }
    }

    /**
     * 判断点是否在多边形内
     * @param {*} point 
     * @param {*} polygon 
     */
    isPointInPolygon(point, polygon) {
        return polygon && polygon.length && TMap.geometry.isPointInPolygon(point, polygon)
    }

    at = undefined
    qt = undefined
    /**
     * 添加路径导航动画
     * @param {*} begin 
     * @param {*} end 
     * @param {*} isPickup 
     * @param {*} perEnd 前序订单下车地点
     * @param {*} pathways
     */
    async addRuningAnimation(begin, end, isPickup, perEnd, pathways) {
        if (gc.process.openAMapMatch) {

            if (this.runingPaths.isInStation && this.runingPaths.isPickup === isPickup) {
                // 车辆已经进入了场站范围
                let _distance = TMap.geometry.computeDistance([new TMap.LatLng(begin.lat, begin.lng), new TMap.LatLng(end.lat, end.lng)])
                _distance = Math.floor(_distance)
                loger.info('行程中', '导航', `车辆已经进入了场站范围`)

                return {
                    code: 0,
                    message: '车辆进入了厂站范围（非首次）',
                    distance: _distance,
                    duration: 1
                }
            }

            // 2024-05-22 绑路搬到服务端进行，前端绑路功能关闭
            // // if (!this.at) {
            // //     this.at = new AMapTrack(`${new Date().getTime()}`)
            // // }
            // if (!this.qt) {
            //     this.qt = new QQMapTrack(`${new Date().getTime()}`);
            // }
            // let _begin = begin
            // // begin = await this.at.mapmatch(_begin)
            // begin = await this.qt.mapmatch(_begin)
            // this.addMapDevPoint(_begin, begin)
        }

        if (gc.process.openAMapMatch) { 
            if (gc.process.openHubOfTrafficDriving && !perEnd) {
                // 无接力单，先判断车辆是否进入了终点厂站范围
                if (this.runingPaths.hub && this.runingPaths.hub.hit) {
                    if (this.isPointInPolygon(new TMap.LatLng(begin.lat, begin.lng), this.runingPaths.hub.polygon)) {
                        // 车辆进入了厂站范围
                        this.runingPaths.isInStation = true

                        this.runingPaths.path = []
                        this.hubOfTrafficDriving(begin, end, isPickup)
                        
                        
                        let _distance = TMap.geometry.computeDistance([new TMap.LatLng(begin.lat, begin.lng), new TMap.LatLng(end.lat, end.lng)])
                        _distance = Math.floor(_distance)
                        loger.info('行程中', '导航', `车辆首次进入了场站范围`)
                        
                        return {
                            code: 0,
                            message: '车辆进入了厂站范围',
                            distance: _distance,
                            duration: 1
                        }
                    }
                }
            }
        }

        // 前序订单目的地图标
        if (perEnd) {
            this.markerLayer.add([
                {
                    id: 'per_end',
                    styleId: 'perEnd_icon',
                    position: new TMap.LatLng(perEnd.lat, perEnd.lng),
                    content: ''
                }
            ])
        } else {
            this.markerLayer.remove(['per_end'])
        }

        // 途径点

        // 地图上应该显示的途径点
        let map_pathway = []
        if (pathways && pathways.length) {
            pathways = pathways.filter(a => !a.passed)
            // 设置最新点
            if (pathways && pathways.length) {
                map_pathway = pathways.map((item, index) => {
                    return {
                        id: 'pathway' + index,
                        styleId: 'perEnd_icon',
                        position: new TMap.LatLng(item.lat, item.lng),
                        content: item.name
                    }
                })
            }
        }
        // 取得旧点，与新点对比，删除多余的，补充新增的
        let _allpathway = this.markerLayer.getGeometries().filter(a => a.id.indexOf('pathway') === 0)
        // 需要删除的点
        let _new_pathwayName = map_pathway.map(a => a.content)
        let _del = _allpathway.filter(a => !_new_pathwayName.includes(a.content))
        // 删除旧点
        if (_del && _del.length) {
            this.markerLayer.remove(_del.map(a => a.id))
        }
        // 需要新增的点
        let _old_pathwaryName = _allpathway.map(a => a.content)
        let _new = map_pathway.filter(a => !_old_pathwaryName.includes(a.content))
        if (_new && _new.length) {
            this.markerLayer.add(_new)
        }

        this.runingPaths.driverPositions.push(begin)
        if (this.runingPaths.isPickup !== isPickup || this.runingPaths.path.length === 0 || !this.runingPaths.isSameEnd(end)) {
            // 无未完成路径,或者终点改变，此时需要预估一条路径
            this.runingPaths.clear()
            this.removeFence();
            this.removeRecommendMarkers();
            this.runingPaths.driverPositions.push(begin)

            // 判断终点是否交通枢纽
            let hub = await this.isHubOfTraffic(end.lat, end.lng)
            if (hub && hub.hit) {
                this.runingPaths.hub = hub
                loger.info('行程中', '终点是交通枢纽', JSON.stringify(hub))
            }

            direction.Enable = true
            let _waypoints = []
            if (perEnd) {
                _waypoints.push(perEnd)
            }
            if (pathways && pathways.length) {
                _waypoints = [..._waypoints, ...pathways]
            }

            let res = await direction.driving(begin, end, isPickup, _waypoints)
            if (res && res.code !== 0) {
                return {
                    code: 1
                }
            }
            if (res && !res.polyline) {
                return {
                    code: 1
                }
            }

            if (hub && hub.hit) {
                let lastPoint = {
                    lat: res.polyline[res.polyline.length - 2],
                    lng: res.polyline[res.polyline.length - 1]
                }
                if (!latlngEq(end, lastPoint)) {
                    res.polyline.push(Number(end.lat))
                    res.polyline.push(Number(end.lng))
                    console.log(res.polyline, end)
                }
            }

            this.runingPaths.path = res.polyline;
            this.runingPaths.distance = res.distance;
            this.runingPaths.duration = res.duration;
            this.runingPaths.isPickup = isPickup;
            this.runingPaths.end = end
            this.runingPaths.perEnd = perEnd

            loger.track('进行中', '获取导航路径', JSON.stringify(this.runingPaths))

            //this.drawDriving(this.runingPaths.path, 'driver_icon', isPickup ? 'user_icon' : 'end_icon')
            this.moveAlongStart(isPickup)
            res.newDirection = true
            return res
        } else {
            // 已有路径，判定司机是否偏航
            let resultSegment = getRemainingSegment(this.runingPaths.path, [Number(begin.lat), Number(begin.lng)], yawThreshold)
            if (resultSegment.code !== 0) {
                console.log('当前坐标', begin)
                this.runingPaths.countYaw++;
                if (this.runingPaths.countYaw > gc.process.getCountYawByForce) {
                    // 偏航十次，强制规划路线
                    loger.warning('订单进行中', '偏航十二次，强制规划路线', { begin, end, perEnd })

                    this.runingPaths.clear()
                    this.removeFence();
                    this.removeRecommendMarkers();
                    this.runingPaths.driverPositions.push(begin)
                    return await this.addRuningAnimation(begin, end, isPickup, perEnd, pathways)

                } else if (this.runingPaths.countYaw > 2) {
                    if (begin.match) {
                        // 绑路成功偏航两次以上，立即执行重新规划路径
                        // 偏航，自调用一次，重新规划路径
                        loger.warning('订单进行中', '偏航大于2次，且偏航的坐标是绑路成功后的坐标，判定为真偏航', { begin, end, perEnd })

                        this.runingPaths.clear()
                        this.removeFence();
                        this.removeRecommendMarkers();
                        this.runingPaths.driverPositions.push(begin)
                        return await this.addRuningAnimation(begin, end, isPickup, perEnd, pathways)
                    }

                    // 判断司机坐标的偏航是否合理，获取偏航前坐标与当前坐标之间的导航线，使用这条路线的长度、时间数据判断这种偏航是否合理，例如使用长度和时间计算出来的时速离谱，则表示这次的偏航是坐标不准导致的，并非真实偏航
                    let currentPosition = begin
                    let beforPosition = this.runingPaths.driverPositions[this.runingPaths.driverPositions.length - this.runingPaths.countYaw - 1]
                    // console.log(beforPosition, currentPosition)
                    let yawRes = await direction.drivingForLbs(beforPosition, currentPosition)
                    if (yawRes.code === 0) {
                        // distance: 0,        duration: 0,
                        let averageSpeed = this.runingPaths.distance / (this.runingPaths.duration * 60)
                        if (yawRes.distance > averageSpeed * this.runingPaths.countYaw * 5) {
                            // console.log(yawRes, this.runingPaths.distance, this.runingPaths.duration, averageSpeed * this.runingPaths.countYaw * 5)
                            // console.log(`第${this.runingPaths.countYaw}次偏航，距离超过合理速度，判定为定位不准，不是偏航`)
                            return {
                                code: 1,
                                countYaw: this.runingPaths.countYaw,
                                resultSegment: resultSegment,
                                message: `第${this.runingPaths.countYaw}次偏航，距离超过合理速度，判定为定位不准，不是偏航`
                            }
                        }
                    }
                    // 偏航，自调用一次，重新规划路径
                    loger.warning('订单进行中', '偏航在2到12次之间，符合偏航逻辑，重新规划路线', { begin, end, perEnd })

                    this.runingPaths.clear()
                    this.removeFence();
                    this.removeRecommendMarkers();
                    this.runingPaths.driverPositions.push(begin)
                    return await this.addRuningAnimation(begin, end, isPickup, perEnd, pathways)
                } else {
                    return {
                        code: 1,
                        countYaw: this.runingPaths.countYaw,
                        resultSegment: resultSegment,
                        message: `第${this.runingPaths.countYaw}次偏航`
                    }
                }
            } else {
                this.runingPaths.countYaw = 0
                // 未偏航，绘制未完成路径
                //this.drawDriving(resultSegment.path, 'driver_icon', isPickup ? 'user_icon' : 'end_icon')
                if (window.vm) {
                    this.moveAlongUpdate(begin, window.vm.intervalTime / 1000)
                } else {
                    this.moveAlongUpdate(begin, 5)
                }
                

                let _distanceto = totalDistance(resultSegment.path)
                let _duration = _distanceto * this.runingPaths.duration / this.runingPaths.distance
                // 判断时速是否低于25Km/h，如果低于这个速度，需要改成这个速度
                let _v25_duration = _distanceto / 7
                if (_duration > _v25_duration) {
                    _duration = _v25_duration
                }
                return {
                    code: 0,
                    distance: Math.ceil(_distanceto),
                    duration: Math.ceil(_duration),
                    polyline: resultSegment.path
                }
            }
        }
    }

    lastQueryTime = 0;

    watchPosition = () => {
        try {
            // 获取当前时间
            this.watchLocationId = navigator.geolocation.watchPosition(async (res) => {
                if (res.coords.latitude && res.coords.longitude) {
                    const currentTime = Date.now();
                    const timeDiff = currentTime - this.lastQueryTime;
                    if (timeDiff < 5000) {
                        return
                    }
                    this.lastQueryTime = currentTime;

                    // 高德的
                    // let _res = await webService.map.convert(res.coords.longitude.toFixed(6), res.coords.latitude.toFixed(6));
                    // if (_res.data.status === '1') {
                    //     let _coords = _res.data.locations.split(',');
                    //     let cacheGPS = {
                    //         timestamp: new Date().getTime(),
                    //         longitude: _coords[0],
                    //         latitude: _coords[1]
                    //     };
                    //     this.showCurrentPoint({ lat: cacheGPS.latitude, lon: cacheGPS.longitude })
                    // } else {
                    //     console.error('watch方法H5原生定位成功后，进行GPS与JCG坐标系转换失败', _res.data)
                    // }

                    // 腾讯的
                    let _res = await webService.map.translate(res.coords.longitude.toFixed(6), res.coords.latitude.toFixed(6));
                    if (_res && _res.longitude && _res.latitude) {
                        let _coords = [_res.longitude, _res.latitude];
                        let cacheGPS = {
                            timestamp: new Date().getTime(),
                            longitude: _coords[0],
                            latitude: _coords[1]
                        };
                        this.showCurrentPoint({ lat: cacheGPS.latitude, lon: cacheGPS.longitude })

                    } else {
                        console.error('watch方法H5原生定位成功后，进行GPS与JCG坐标系转换失败', _res.data)
                    }

                }
            }, async (err) => {
                console.error(err)
                this.clearWatchPosition()
            })

        } catch (e) {
            console.error('实时定位失败', e)
        }
    }

    clearWatchPosition = () => {
        if (this.watchLocationId !== null) {
            try {
                navigator.geolocation.clearWatch(this.watchLocationId);
            } catch (err) {
                console.error(err)
            } finally {
                this.removePoint('current_point')
            }
        }
    }

    setMapOffset = (offset) => {
        this.map.setOffset({
            x: offset.x ? offset.x : 0,
            y: offset.y ? offset.y : 0
        })
    }

    resetMapOffset = (offset) => {
        this.map.setOffset({
            x: offset.x ? offset.x : 0,
            y: offset.y ? offset.y : 0
        })
    }

    /**
     * 绘制起点到终点导航路线动画详情展示
     * @param {*} paths 
     * @param {*} beginStyleId 
     * @param {*} endStyleId 
     * @param {*} beginLocationName 
     * @param {*} endLocationName 
     * @param {*} isAnimation
     * @param {*} pathways
     */
    drawDrivingMoveAlong(paths, beginStyleId, endStyleId, beginLocationName, endLocationName, isAnimation, pathways, positionBottom = 65) {
        let _path = []
        for (let i = 0; i < paths.length; i += 2) {
            _path.push({
                lat: paths[i],
                lng: paths[i + 1]
            })
        }

        let polylinePoints = _path.map(a => new TMap.LatLng(a.lat, a.lng))
        this.markerOrderDetails.add([
            {
                id: 'rm_begin',
                styleId: beginStyleId,
                position: polylinePoints[0],
                content: beginLocationName
            },
            {
                id: 'rm_end',
                styleId: endStyleId,
                position: polylinePoints[polylinePoints.length - 1],
                content: endLocationName
            }
        ])

        if (isAnimation) {
            this.markerOrderDetails.add([
                {
                    id: 'car',
                    styleId: 'car_down',
                    position: polylinePoints[0]
                }
            ])
        }

        this.polylineLayer.add([
            {
                id: 'pl_0',
                styleId: 'style_driving',
                paths: polylinePoints
            }
        ])

        if (isAnimation) {
            this.markerOrderDetails.moveAlong(
                {
                    "car": {
                        path: polylinePoints,
                        speed: 500
                    }
                },
                {
                    autoRotation: true
                }
            )
        }
        let { sw, ne } = getPointsArea(_path)
        let bounds = new TMap.LatLngBounds(new TMap.LatLng(sw.lat, sw.lng), new TMap.LatLng(ne.lat, ne.lng))
        this.map.fitBounds(bounds, {
            padding: {
                top: 45,
                right: 30,
                bottom: positionBottom,
                left: 30
            }
        })

        // 绘制途径点
        if (pathways && pathways.length) {
            this.markerLayer.add(
                pathways.map((item, index) => {
                    return {
                        id: 'pathway' + index,
                        styleId: 'perEnd_icon',
                        position: new TMap.LatLng(item.lat, item.lng),
                        content: item.name
                    }
                }))
        }
    }
}

export default QQMap;

