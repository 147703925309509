import axios from 'axios'
import { encryptAES_GCM } from './aesHelper'
import { stageApiUrl } from './variable'

const logSave = async (log) => {
    let url = `https://${stageApiUrl}/stage-api/log/interface/save`
    let postDataString = encryptAES_GCM(JSON.stringify(log))
    console.log(postDataString)
    let { data } = await axios.post(url, postDataString, {
        headers: {
            'Content-Type': 'text/plain'
        }
    })
    console.log(data)
    return data
}

export {
    logSave
}