//eslint-disable-next-line 
export default {
  main: {
    k000: 'zh',
    k001: '您将在此上车',
    k002: '获取位置失败',
    k003: '该地点仅支持定点位置上车',
    k004: '查询企业基本信息失败',
    k005: '获取中',
    k006: '定位失败请手动输入',
    k007: '当前定位失败，请检查您的网络!',
    k008: '进行中订单',
    k009: '用车审批',
    k010: '个人支付',
    k011: '我的行程',
    k012: '发票',
    k013: '用车场景',
    k014: '个人设置',
    k015: '使用指南',
    k016: '优惠券',
    k017: '企业账户',
    k018: '用车报告',
    k019: '请选择用车场景',
    k020: '请选择起点',
    k021: '请选择用车时间',
    k022: '请选择航班',
    k023: '请选择服务模式',
    k024: '您有 {{count}} 笔进行中的行程',
    k025: '笔进行中的行程',
    k026: '{{ who }}等待接单',
    k027: '正在赶来接您',
    k028: '司机已到达',
    k029: '行程中',
    k030: '用车场景',
    k031: '加载中',
    k032: '无可用场景',
    k033: '加载失败',
    k034: '可用额度',
    k035: '元',
    k036: '无限制',
    k037: '(审批恢复)',
    k038: '可用车型',
    k039: '工作日',
    k040: '查看详情',
    k041: '可用日期:',
    k042: '无限',
    k043: '至',
    k044: '可用时间:',
    k045: '周一二三四五六日',
    k046: '休息日',
    k047: '单程',
    k048: '往返',
    k049: '任意地点',
    k050: '负责人:',
    k051: '普通型',
    k052: '舒适型',
    k053: '商务型',
    k054: '豪华型',
    k055: '选择用车时间',
    k056: '月',
    k057: '日',
    k058: '年',
    k059: '点',
    k060: '分',
    k061: '城市中文名或拼音',
    k062: '取消',
    k063: '当前城市:',
    k064: '当前地址:',
    k065: '从哪出发',
    k066: '请输入您的航班号',
    k067: '请输入航班号信息',
    k068: '确认',
    k069: '航班动态将实时同步给司机,便于准时接您',
    k070: '选择航班',
    k071: ' 星期',
    k072: '日一二三四五六七',
    k073: '无航班信息',
    k074: '客服热线',
    k075: '点击查看',
    k076: '加载中…',
    k077: '周',
    k078: '单次额度:',
    k079: '用车城市:',
    k080: '用车地点:',
    k081: '申请用车',
    k082: '行前审批',
    k083: '定位中...',
    k084: '您要去哪',
    k085: '你要去哪儿?',
    k086: '未知',
    k087: '欢迎来到',
    k088: '请选择您所在的区域',
    k089: '将根据您的选择，为您',
    k090: '推荐可乘车地点',
    k091: '在此出发',
    k092: '不在此地址上车',
    k093: '您当前位于',
    k094: '已为您',
    k095: '推荐最佳乘车地点',
    k096: '确定乘车地点',
    k097: '地址选择框',
    k098: '账户信息不全',
    k099: '请先设置您的手机号码',
    k0100: '前往设置',
    k0101: '待分配',
    k0102: '待服务',
    k0103: '已取消',
    k0104: '礼宾取消',
    k0105: '未知状态',
    k0106: '等待接单',
    k0107: '稍后,司机正赶来接您',
    k0108: '车辆已到达',
    k0109: '行程开始..',
    k0110: '服务结束',
    k0111: '订单完成',
    k0112: '您的行程已被系统取消',
    k0113: '已取消',
    k0114: '等待司机',
    k0115: '司机到达',
    k0116: '行程中',
    k0117: '服务结束',
    k0118: '订单完成',
    k0119: '已取消',
    k0120: '请输入地址',
    k0121: '常用地址1',
    k0122: '常用地址2',
    k0123: '点击设置',
    k0124: '设置失败',
    k0125: '删除失败',
    k0126: '请输入列车车次号',
    k0127: '列车信息',
    k0128: '请选择列车到站日期',
    k0129: '查询',
    k0130: '经停车站',
    k0131: '开始用车',
    k0132: "暂无用车场景",
    k0133: "当前无用车权限",
    k0134: '预约管家正在为您呼叫中...',
    k0135: '在此下车',
    k0136: '确认下车地点',
    k0137: '不在此地址下车',
    k0138: '推荐可下车地点',
    k0139: '推荐最佳下车地点',
    k0140: '该地点有多个适合下车的位置',
    k0141: '您已选择',
    k0142: '包车',
    k0143: '请选择包车时间',
    k0144: '计价规则',
    k0145: '正在持续为您预约车辆…',
    k0146: '首页',
    k0147: '大巴车',
    k0148: '正在获取上车地点',
    k0149: '未找到相关地址，请检查输入信息',
    k0150: '待支付订单',
    k0151: '您当前有一笔未支付订单，完成支付避免影响您后续用车',
    k0152: '前往支付',
    k0153: '地图加载中...',
    k0154: '展示其余{{length}}条结果',
    k0155: '定位失败',
    k0156: '知情同意书',
    k0157: '我已阅读并同意《软件使用协议》',
    k0158: '请您阅读勾选知情同意书',
    k0159: '软件使用协议',
    k0160: '我已阅读并同意《软件使用协议》',
    k0161: '请您阅读勾选软件使用协议',
    k0162: '支付中心',
    k0163: '我的反馈',
    k0164: '途经点',
    k0165: '请输入途经点',
    k0166: '请补充车次号或日期信息',
    k0167: '请您在{{day}}日{{time}}左右到达上车地点',
    k0168: '手机',
    k0169: '无可用车次',
    k0170: '请选择车次',
    k0171: '您有异常行程待确认，请确认后再用车',
    k0172: '前往确认',
    k0173: '已阅读并同意',
    k0174: '隐私协议',
    k0175: '立即',
    k0176: '包车',
    k0177: '预约',
    k0178: '接机',
    k0179: '接站',
  },
  account: {
    k001: '可用额度',
    k002: '已冻结金额',
    k003: '切换账户',
    k004: '充值金额',
    k005: '月份',
    k006: '类型',
    k007: '金额',
    k008: '暂无数据',
    k009: '开始:',
    k010: '结束:'
  },
  accountCharge: {
    k001: '可用额度',
    k002: '充值金额',
    k003: '元',
    k004: '其它金额',
    k005: '邀请码:',
    k006: '请输入邀请码(选填)',
    k007: '邀请码长度不正确，至多10个字符',
    k008: '我已阅读并同意',
    k009: '《易企出行商务条款》',
    k010: '立即充值',
    k011: '请输入正确邀请码',
    k012: '请选择充值金额',
    k013: '请先阅读并同意《易企出行商务条款》',
    k014: '加载中',
    k015: '请输入整数',
    k016: '加载中…',
  },
  approval: {
    k001: '起点：',
    k002: '终点：',
    k003: '用车时间：',
    k004: '里程：',
    k005: '时长：',
    k006: '待审批',
    k007: '已审批',
    k008: '元',
    k009: '全选',
    k010: '拒绝',
    k011: '通过',
    k012: '月份：',
    k013: '合计金额：',
    k014: '订单异常',
    k015: '订单编号：',
    k016: '公里',
    k017: '米',
    k018: '用车场景：',
    k019: '用车事由：',
    k020: '没有更多了',
    k021: '用车场景明细',
    k022: '人员明细',
    k023: '风险提示',
    k024: '知道了',
    k025: '当前暂无待审批订单',
    k026: '当前暂无已审批订单',
    k027: '加载中',
    k028: '操作成功',
    k029: '暂无待审核订单',
    k030: '请选择审核订单',
    k031: '提交',
    k032: '取消',
    k033: '请输入拒绝原因',
    k034: '确认通过选中订单?',
    k035: '公里',
    k036: 'Loading',
    k037: '未通过',
    k038: '通过',
    k039: '拒绝',
    k040: '未知状态',
    k041: '订单异常',
    k042: '本次行程超出以下标准',
    k043: '操作失败',
    k044: '用车类型：',
    k045: '半日包',
    k046: '全日',
    k047: '行前申请单号：',
    k048: '该订单已更换审批人',
  },
  approvalDetails: {
    k001: '费用明细',
    k002: '元',
    k003: '如对费用明细有疑问可拨打{{serviceNumber}}进行详细咨询',
    k004: '审批日志',
    k005: '最终审批状态:',
    k006: '时间',
    k007: '审批人:',
    k008: '原因:'
  },
  callPolice: {
    k001: '如遇险情,保持冷静,依法报警',
    k002: '谎报警情将被依法追究法律责任',
    k003: '行程信息',
    k004: '当前位置参考',
    k005: '当你报警后,平台会立即采取以下措施',
    k006: '根据警方要求,提供行程信息',
    k007: '通知紧急联系人',
    k008: '立即添加',
    k009: '24小时客服专线',
    k010: '拨打110报警',
    k011: '定位失败!!!',
    k012: '紧急'
  },
  chooseCoupon: {
    k001: '满 {{money}} 可用',
    k002: '企业',
    k003: '个人',
    k004: '查看详情',
    k005: '可用平台:',
    k006: '可用车型:',
    k007: '可用城市:',
    k008: '除 ',
    k009: '无限制',
    k010: '不使用优惠券',
    k011: '优惠券抵扣时以实际的用车数据为准',
    k012: '卡券不可用',
    k013: '不符合城市限制',
    k014: '不符合车型限制'
  },
  chooseScene: {
    k001: '可用额度:',
    k002: '元',
    k003: '无限制',
    k004: '(审批恢复)',
    k005: '查看详情',
    k006: '可用日期:',
    k007: '无限',
    k008: '周一二三四五六日',
    k009: '工作日',
    k010: '休息日',
    k011: '单程',
    k012: '往返',
    k013: '任意地点',
    k014: '负责人',
    k015: '普通型',
    k016: '舒适型',
    k017: '商务型',
    k018: '豪华型',
    k019: '周',
    k020: '可用车型',
    k021: '可用时间:'
  },
  confirm: {
    k001: '增值服务',
    k002: '单',
    k003: '用车说明',
    k004: '(选填)',
    k005: '{{validCount}}张可用',
    k006: '无可用优惠券',
    k007: '优惠券',
    k008: '起点',
    k009: '终点',
    k010: '乘车人电话',
    k011: '请输入用车人手机号',
    k012: '用车场景',
    k013: '所属项目',
    k014: '选择所属项目',
    k015: '暂无所属项目',
    k016: '企业账户',
    k017: '我已阅读并同意',
    k018: '企业出行条款',
    k019: '《知情同意书》',
    k020: '确认叫车',
    k021: '重选车型',
    k022: '紧急联系人说明',
    k023: '您尚未设置紧急联系人，如需使用随行卫士功能，请点击前往设置，完成紧急联系人设置',
    k024: '取消',
    k025: '前往设置',
    k026: '请先填写正确用车手机号',
    k027: '请先填写用车说明',
    k028: '请先阅读并同意<企业出行条款>',
    k029: '加载中',
    k030: '页面停留时间过长，请返回前页重新估价。',
    k031: '满 {Yuan} 可用',
    k032: '企业',
    k033: '个人',
    k034: '查看详情',
    k035: '可用平台',
    k036: '可用车型',
    k037: '可用城市',
    k038: '除 {city} 无限制',
    k039: '申请单',
    k040: '《企业出行条款》',
    k041: '请填写',
    k042: '请填写正确',
    k043: '请选择',
    k044: '优先低碳出行，可以确保您出行效率的同时，优先派单给附近清洁能源的车型',
    k045: '好的，谢谢提醒',
    k046: '推荐您选择低碳出行',
    k047: '优先低碳车辆出行',
    k048: '通讯录',
    k049: '拉起通讯录失败',
    k050: '请先到个人中心中导入通讯录',
    k051: '确认',
    k052: '请填写项目编号',
    k053: '项目编号',
    k054: '请填入项目编号或无项目',
    k055: '编号检索',
    k056: '输入文字过滤选项',
    k057: '检索',
    k058: '请选择会议',
    k059: '乘车人姓名',
    k060: '请输入乘车人姓名',
    k061: '成本中心',
    k062: '请选择成本中心',
    k063: '审批人',
    k064: '请选择审批人',
    k065: '案件成本中心',
    k066: '请选择案件成本中心',
    k067: '请输入内容',
    k068: '去支付',
    k069: '我知道了',
    k070: '代人叫车',
    k071: '欢迎使用代叫车服务',
    k072: '请输入乘车人电话',
    k073: '乘车人姓名(选填)',
    k074: '确认',
    k075: '取消代叫'
  },
  contact: {
    k001: '请输入紧急联系人电话号码',
    k002: '请输入验证码',
    k003: '获取验证码',
    k004: '{{ seconds }}秒后重发',
    k005: '为了您的行程安全，建议提前设置紧急联系人，行程中触发一键报警功能时，系统会主动通知紧急联系人。',
    k006: '在选择“随行卫士”服务时，行程期间每10分钟系统会向紧急联系人发送关于乘车人行程信息的短信；每单服务费2元，与车费合并计算。',
    k007: '关于随行卫士详情请在增值服务页面查看。',
    k008: '提交',
    k009: '请输入手机号',
    k010: '请输入正确手机号',
    k011: '紧急联系人手机号与用户手机号相同，请重新输入',
    k012: '紧急联系人',
    k013: '验证码',
    k014: '请填写图片验证码',
    k015: '发送验证码失败'
  },
  coupons: {
    k001: '未使用',
    k002: '使用记录',
    k003: '已过期',
    k004: '无相关优惠券信息',
    k005: '满 {money} 可用',
    k006: '企业',
    k007: '个人',
    k008: '查看详情',
    k009: '可用平台',
    k010: '可用车型',
    k011: '可用城市',
    k012: '除 {limit} 无限制',
    k013: '当前优惠券冻结，行程结束后若不满足使用规则可恢复为可用状态。',
    k014: '使用详情'
  },
  estimate: {
    k001: '全程',
    k002: '暂无限制范围内可用车型',
    k003: '操作失败',
    k004: '上次选择',
    k005: '型',
    k006: '全选',
    k007: '预估',
    k008: '同时呼叫',
    k009: '预估 {{Yuan}} 元',
    k010: '已选择',
    k011: '服务器错误,请刷新页面重试',
    k012: '立即叫车',
    k013: '预估价超过单次打车额度限制，请选择其他用车场景',
    k014: '预估价超过场景额度限制，请选择其他用车场景或联系企业管理员',
    k015: '车费预估说明',
    k016: '动态加价x',
    k017: '关闭',
    k018: '元',
    k019: '种车型',
    k020: '倍',
    k021: '已优惠',
    k022: '0',
    k023: '分钟',
    k024: '预计行驶',
    k025: '公里',
    k026: '米',
    k027: '乘车人手机号',
    k028: '请输入手机号码',
    k029: '请输入正确手机号',
    k030: '确认',
    k031: '乘车人:',
    k032: '发起叫车',
    k033: '计价规则',
    k034: '半日包(4小时)',
    k035: '包车(8小时)',
    k036: '巴士',
    k037: '套餐预估价',
    k038: '超里程加收',
    k039: '超时长加收',
    k040: '预估价是根据实时交通状况，时间等因素预估计算得出，存在波动，仅供参考。',
    k041: '此车型不可取消',
    k042: '接机场景不可更改上车地点',
    k043: '已选',
    k044: '个',
    k045: '已选以下 {{count}} 个服务商',
    k046: '请选择服务商',
    k047: '确定',
    k048: '一口价'
  },
  invoice: {
    k001: '用车时间：',
    k002: '元',
    k003: '起点：',
    k004: '终点：',
    k005: '用车人：',
    k006: '没有更多了',
    k007: '全选',
    k008: '{{count}} 个行程，共计',
    k009: '下一步',
    k010: '月份：',
    k011: '合计金额：',
    k012: '发票金额',
    k013: '{{count}} 个行程',
    k014: '待开票',
    k015: '已开票',
    k016: '暂无待开票订单',
    k017: '暂无已开票订单',
    k018: '请选择开票订单',
    k019: '请您确认是否存在同一订单两笔支付请一并勾选前往开票',
  },
  invoiceDetails: {
    k001: '发票详情',
    k002: '发票类型',
    k003: '发票抬头',
    k004: '公司税号',
    k005: '发票金额',
    k006: '提交时间',
    k007: '接收方式',
    k008: '电子邮箱',
    k009: '请输入邮箱',
    k010: '相关行程',
    k011: '含 {{count}} 个行程',
    k012: '查看',
    k013: '如您对发票信息有任何疑问，请拨打 {{customerServiceNumber}} 电话咨询。',
    k014: '重发电子发票',
    k015: '请输入正确邮箱',
    k016: '加载中',
    k017: '操作成功',
    k018: '开户行',
    k019: '地址',
    k020: '元',
  },
  login: {
    k001: '请输入手机号',
    k002: '请输入验证码',
    k003: '获取验证码',
    k004: '登录',
    k005: '请输入正确的手机号',
    k006: '发送验证码失败',
    k007: '服务号',
    k008: '请输入邮箱',
    k009: '请输入正确的邮箱',
    k010: '切换成邮箱登录',
    k011: '切换成手机号登录',
    k012: '请输入图片验证码',
  },
  noAuth: {
    k001: '您暂未开通用车权限',
    k002: '请联系管理员或拨打服务热线{{customerServiceTelephone}}',
    k003: '无权限',
    k004: '您已被禁用车辆功能，解禁时间为',
    k005: '请联系管理员或拨打服务热线',
  },
  orderdetails: {
    k001: '元',
    k002: '行程结束，点击支付剩余金额',
    k003: '已成功支付预付费金额',
    k004: '更改用车事由', 
    k005: '订单评价',
    k006: '订单编号：',
    k007: '用车时间：',
    k008: '里程：',
    k009: '公里',
    k010: '米',
    k011: '时长：',
    k012: '用车类型：',
    k013: '车辆信息：',
    k014: '企业支付：',
    k015: '个人支付：',
    k016: '费用明细',
    k017: '如对费用明细有疑问可拨打{{customerServiceTelephone}}进行详细咨询',
    k018: '企业审批相关',
    k019: '最终审批状态',
    k020: '时间：',
    k021: '审批人：',
    k022: '审批原因：',
    k023: '关闭',
    k024: '更改用车事由',
    k025: '提交',
    k026: '取消',
    k027: '请输入用车原因（100字以内）',
    k028: '注：审批状态为已通过时，不可更改用车事由',
    k029: '服务错误，请刷新页面重试',
    k030: '请输入用车理由',
    k031: '提交成功',
    k032: '预付费剩余金额已发起退款',
    k033: '行程结束，已成功抵扣',
    k034: '本次行程超出标准,请确认',
    k035: '用车场景：',
    k036: '场景Code：',
    k037: '本次行程超出以下标准',
    k038: '查看说明',
    k039: '收起说明',
    k040: '预约管家为您代叫不同平台车型的差价已直接扣除,无需由您支付',
    k041: '预约管家为员工代叫不同平台车型的差价已直接扣除，无需由企业支付。',
    k042: '特别满意',
    k043: '感觉一般',
    k044: '非常糟糕',
    k045: '待支付',
    k046: '已支付',
    k047: '元',
    k048: '已优惠',
    k049: '费用明细',
    k050: '预付费详情',
    k051: '评价本次行程',
    k052: '匿名',
    k053: '投诉进度',
    k054: '提交投诉',
    k055: '正在处理',
    k056: '处理完成',
    k057: '订单详情',
    k058: '行程已取消',
    k059: '订单已取消，您可以重新打车。',
    k060: '重新打车',
    k061: '立即支付',
    k062: '补充评价：',
    k063: '抱歉给您带来不便的出行体验',
    k064: ',如有需要',
    k065: '点击投诉',
    k066: '补充评价',
    k067: '企业审批相关',
    k068: '历史审批',
    k069: '展开审批记录',
    k070: '第{{index}}次',
    k071: '展开',
    k072: '收起',
    k073: '重新提交',
    k074: '拒绝原因：',
    k075: '定位失败',
    k076: '本次行程超出标准',
    k077: '企业已支付：',
    k078: '个人已支付：',
    k079: '个人待支付：',
    k080: '行程已取消,',
    k081: '请支付取消费',
    k082: '产生取消费',
    k083: '个人待支付',
    k084: '订单总额',
    k085: '订单异常',
    k086: '当前还可修改',
    k087: '',
    k088: '需自费',
    k089: '费用说明',
    k090: '此处显示金额仅供参考，具体金额以实际支付金额为准。',
    k091: '支付成功',
    k092: '重新提交：',
    k093: '结算中...',
    k094: '小调查 下车位置是否正确？',
    k095: '否',
    k096: '是'
  },
  orderevaluate: {
    k001: '感谢您的肯定, 不妨推荐一下让更多的同事看到',
    k002: '抱歉影响您的出行体验, 平台将对司机进行管束',
    k003: '请输入评价内容',
    k004: '评价内容：',
    k005: '投诉订单',
    k006: '投诉进度',
    k007: '处理中',
    k008: '处理完毕',
    k009: '“司机交由相关服务部门进行处理，',
    k010: '平台会加强车辆信息的监控。”',
    k011: '“客服正在处理，请耐心等待...”',
    k012: '匿名评价',
    k013: '请您评分',
    k014: '请您输入评价',
    k015: '评价成功',
    k016: '请选评价标签'
  },
  orders: {
    k001: '等待接单',
    k002:'等待司机',
    k003: '司机到达',
    k004: '进行中',
    k005: '行程结束',
    k006: '已完成',
    k007: '已取消',
    k008: '实时',
    k009: '预约',
    k010: '接机',
    k011: '预付',
    k012: '冻结金额',
    k013: '用车地点：',
    k014: '用车时间：',
    k015: '起点：',
    k016: '终点：',
    k017: '待开票',
    k018: '已开票',
    k019: '没有更多了',
    k020: '暂无行程',
    k021: '月份：',
    k022: '合计金额：',
    k023: 'Loading',
    k024: '未知状态',
    k025: '接站',
    k026: '审批状态：',
    k027: '待支付',
    k028: '去开票',
    k029: '订单异常',
    k030: '待审批',
    k031: '包车',
    k032: '请联系平台在线客服申请开票',
    k033: '取消费用',
    k034: '注：审批状态为已通过时，不可更改用车事由',
    k035: '共计',
    k036: '元'
  },
  payments: {
    k001: '未支付',
    k002: '已支付',
    k003: '全选',
    k004: '支付',
    k005: '当前暂无个人支付订单',
    k006: '本月暂无已支付订单',
    k007: '月份：',
    k008: '合计金额：',
    k009: '元',
    k010: '请确认您是否已完成支付',
    k011: '暂未查询到支付结果，若您已经支付，请联系客服',
    k012: '支付失败',
    k013: '知道了',
    k014: '加载中…',
    k015: '请先选择支付订单',
    k016: '订单总额:',
    k017: '报销',
    k018: '预支付',
    k019: '审批被拒绝，可更改用车事由后重新提交审批',
    k020: '用车时间:',
    k021: '起点:',
    k022: '终点:',
    k023: '城市:',
    k024: '支付流水：',
    k025: '共 {{count}} 单行程',
    k026: '预付费',
    k027: '其中 {{money}} 元已返还',
    k028: '获取{{appid}}支付连接失败',
    k029: '消费金抵扣',
    k030: '请选择支付订单'
  },
  perApply: {
    k001: '限地点',
    k002: '限城市',
    k003: '申请规则',
    k004_1: '1.申请时需输入用车所需的起始地、目的地信息，申请通过后，',
    k004_2: '可在申请位置为中心半径{{beginDistance}}m范围内选择起始地、',
    k004_3: '可在申请城市内选择起始地、',
    k004_4: '申请位置为中心半径{{endDistance}}m范围内选择目的地发起订单。',
    k004_5: '申请城市内选择目的地发起订单。',
    k005: '2.用车时间最多可申请{{useCarTimeSpan}}天，精确到分钟。超出申请时间后该笔申请单不可发起用车。',
    k006: '5.每名员工同时可发起多笔申请。',
    k007: '申请单列表',
    k008: '用车场景',
    k009: '请选择用车场景',
    k010: '起点模式',
    k011: '用车起始地',
    k012: '请选择起始地',
    k013: '用车起始城市',
    k014: '请选择起始城市',
    k015: '终点模式',
    k016: '用车目的地',
    k017: '请选择目的地',
    k018: '用车目的城市',
    k019: '请选择目的城市',
    k020: '用车起始时间',
    k021: '请选择 年/月/日 时:分',
    k022: '请选择起始时间',
    k023: '用车结束时间',
    k024: '请先选择起始时间',
    k025: '请选择结束时间',
    k026: '用车次数',
    k027: '请输入用车次数',
    k028: '用车金额',
    k029: '请输入用车用车金额',
    k030: '申请原因',
    k031: '请输入申请原因',
    k032: '提交申请',
    k033: '返回',
    k034: '提交成功',
    k035: '提交失败',
    k036: '请填写完整信息后重试',
    k037: '现在返回后，将不会保存编辑的内容',
    k038: '请填写申请原因',
    k039: '当前无可用行前审批场景',
    k040: '3.单次申请单用车金额上限为不限。',
    k040_1: '3.单次申请单使用金额上限为{{useCarMaxMoney}}元，超出用车金额该笔申请单不可发起用车。',
    k041: '4.单次申请单使用次数上限为 1 次，超出用车次数该笔申请单不可发起用车。',
    k041_1: '4.单次申请单使用次数上限为{{useCarMaxTimes}}次，超出用车次数该笔申请单不可发起用车。',
  },
  perApplyRecord: {
    k001: '全部',
    k002: '未使用',
    k003: '使用中',
    k004: '已使用',
    k005: '已过期',
    k006: '待审批',
    k007: '审批中',
    k008: '已通过',
    k009: '已拒绝',
    k010: '暂无记录',
    k011: '申请单状态：',
    k012: '审批状态：',
    k013: '服务错误，查询失败',
    k014: '加载中...',
    k015: '可用金额：{{canUseMoney}}元',
    k016: '可用次数：{{canUseCount}}次'
  },
  prepaidProgress: {
    k001: '预付费规则',
    k002: '1.当个人支付金额超过**元时，个人支付部分金额需预支付。',
    k003: '2.预支付金额订单接受后，多退少补。',
    k004: '支付流水：',
    k005: '复制成功',
    k006: '复制',
    k007: '预付车费 {{payMoney}} 元',
    k008: '预付款已被抵扣 {{money}} 元车费',
    k009: '剩余 {{money}} 元已发起退款。',
    k010: '处理结果',
    k011: '平台已受理退款申请',
    k012: '您的剩余部分金额已发起退款，预计将会在3个工作日内入账。',
    k013: '联系客服：'
  },
  register: {
    k001: '请输入所在企业名称',
    k002: '请输入您的真实姓名',
    k003: '请输入您的手机号码',
    k004: '请输入验证码',
    k005: '我已详细阅读',
    k006: '《用户须知》',
    k007: '并同意',
    k008: '获取验证码',
    k009: '提交注册'
  },
  report: {
    k001: '用车场景',
    k002: '城市',
    k003: '车型',
    k004: '开始统计',
    k005: '结束统计',
    k006: '订单量及消费金额',
    k007: '全部',
    k008: '暂无内容',
    k009: '用车场景的单量及金额分布',
    k010: '车型的单量及金额分布',
    k011: '金额',
    k012: '订单',
    k013: '订单(笔)',
    k014: '金额(元)',
    k015: '订单量',
    k016: '结束时间不能小于开始时间'
  },
  runing: {
    k001: '行程取消',
    k002: '行程结束',
    k003: '不存在订单或订单已经完成',
    k004: '服务错误，请刷新页面重试',
    k005: '确认取消订单?',
    k006: '正在努力为您寻找司机,再等一会吧',
    k007: '取消订单',
    k008: '继续等待',
    k009: '临近出发时间可见司机位置',
    k010: '公里',
    k011: '米',
    k012: '已等待',
    k013: '正在为您持续呼叫',
    k014: '加载中',
    k015: '含{{carSource}}-{{carLevelName}}型等{{carLength}}个车型,预估费用{{price}}元',
    k016: '正在呼叫 {{carLength}} 种车型...',
    k017: '型',
    k018: '预估',
    k019: '增加车型，更快上车',
    k020: '全选',
    k021: '追加呼叫',
    k022: '已选择',
    k023: '同时呼叫',
    k024: '车距',
    k025: '预计',
    k026: '分钟后抵达',
    k027: '接机礼宾',
    k028: '司机头像',
    k029: '拉起拨号盘',
    k030: '爽约或拒载',
    k031: '司机距离较远',
    k032: '车辆信息不符',
    k033: '其它原因',
    k034: '继续用车',
    k035: '修改目的地',
    k036: '一键报警',
    k037: '行程分享',
    k038: '用车原因',
    k039: '订单评价',
    k040: '操作失败',
    k041: '未能取得车辆实时位置,请刷新页面后重试.',
    k042: '取消',
    k043: '修改地址',
    k044: '确定',
    k045: '确认修改目的地?',
    k046: '修改目的地至',
    k047: '后预估价格变更为',
    k048: '目的地修改成功!',
    k049: '提交',
    k050: '提交用车说明',
    k051: '分享我的行程',
    k052: '至',
    k053: '秒',
    k054: '司机已等候',
    k055: '分钟',
    k056: "请选择取消原因",
    k057: '接机礼宾引导服务',
    k058: '正在为您安排接机礼宾员,稍后为您发送礼宾信息...',
    k059: '取消服务',
    k060: '编号',
    k061: '将在机场到达口恭候您',
    k062: '联系礼宾',
    k063: '无法和礼宾员取得联系',
    k064: '礼宾员服务态度差',
    k065: '礼宾员要求我取消',
    k066: '信息有误,重新下单',
    k067: '暂不需要礼宾服务了',
    k068: '关闭',
    k069: '已有司机接单',
    k070: '元',
    k071: '暂无车型',
    k072: '种车型',
    k073: '个人支付部分超出预支付金额{{prepaidAmount}}元，支付后增加车型',
    k074: '个',
    k075: '接机礼宾头像',
    k076: '呼叫客服',
    k077: '正在为您分配',
    k078: '请选择车型',
    k079: '增加车型',
    k080: '继续等待',
    k081: '附近车辆少，',
    k082: '是否增加车型享更快应答?',
    k083: '订单已经取消',
    k084: '系统提示',
    k085: '您的订单被平台改派，正在为您重新派单?',
    k086: '取消',
    k087: '确定',
    k088: '取消预约',
    k089: '继续等待',
    k090: '正在为您持续呼叫，确定要取消预约本次行程吗？',
    k091: '预约管家开始为您服务...',
    k092: '正在全力为您呼叫司机...',
    k093: '预计很快完成本次预约...',
    k094: '请您保持耐心继续等待...',
    k095: '如有任何疑问请拨打服务热线：',
    k096: '{{timeZh}}出发',
    k097: '预计将在出发前30分钟完成预约',
    k098: '很抱歉，由于附近车辆不足，暂无司机应答',
    k099: '客服将与您联系，建议您重新叫车',
    k0100: '若有任何疑问请拨打服务热线：',
    k0101: '重新叫车',
    k0102: '请在上车后告知司机尾号为',
    k0103: '行程预约失败',
    k0104: '很抱歉, 行程预约失败',
    k0105: '预约管家正在为您服务中...',
    k0106: '应用内转发',
    k0107: '选择分享途径',
    k0108: '复制链接给好友',
    k0109: '预计本单将会减少{{carbonEmission}}g碳排放量',
    k0110: '系统提示',
    k0111: '该订单已被平台取消',
    k0112: '知道了',
    k0113: '您的取消订单申请已发送，请耐心等待~',
    k0114: '客服人员正在处理，预约成功后，系统将自动推送车辆司机等信息，请留意查收!',
    k0115: '订单详情',
    k0116: '起点',
    k0117: '终点',
    k0118: '联系电话',
    k0119: '车型',
    k0120: '订车类型',
    k0121: '用车时间',
    k0122: '半日包',
    k0123: '全日',
    k0124: '正在为您优先选择新能源车型',
    k0125: '几秒钟的等待, 也是环保的一份心意',
    k0126: '智能派单',
    k0127: '距离优先',
    k0128: '响应优先',
    k0129: '低价优先',
    k0130: '司机，请稍等...',
    k0131: '智能派单',
    k0132: '距离优先',
    k0133: '响应优先',
    k0134: '低价优先',
    k0135: '请选择派车模式',
    k0136: '当前订单为接力单，司机即将完成上一单，请耐心等待',
    k0137: '当前附近司机较少，已为您匹配最优司机',
    k0138: '是否取消该笔订单？',
    k0139: '当前司机侧信号弱，位置更新延迟，请稍后。',
    k0140: '平台不支持三方通话，请用乘车手机号拨打电话',
    k0141: '感谢耐心等待，若在临近预约时间前取消行程，可能需支付取消费。',
    k0142: '修改失败',
    k0143: '平台暂不支持此目的地的修改',
    k0144: '继续用车',
    k0145: '请选择或输入原因',
    k0146: '提交',
    k0147: '请输入其他原因',
    k0148: '加速应答，更快上车!',
    k0149: '现在是叫车高峰，可用司机较少。',
    k0150: '当前附近可用司机较少，添加',
    k0151: '元调度费, 可鼓励更多司机尽快接单。',
    k0152: '确认添加',
    k0153: '我再等等',
    k0154: '取消中'
  },
  runingOrders: {
    k001: '起点:',
    k002: '终点:',
    k003: '叫车人:',
    k004: '当前暂无进行中订单',
    k005: '乘车人手机号:',
    k006: '车牌号:'
  },
  scenes: {
    k001: '无可用场景',
    k002: '可用额度:',
    k003: '元',
    k004: '无限制',
    k005: '(审批恢复)',
    k006: '可用车型',
    k007: '查看详情',
    k008: '可用日期：',
    k009: '无限',
    k010: '至',
    k011: '可用时间：',
    k012: '周一二三四五六日',
    k013: '工作日',
    k014: '休息日',
    k015: '单次额度',
    k016: '用车城市',
    k017: '用车地点',
    k018: '单程',
    k019: '往返',
    k020: '任意地点',
    k021: '负责人',
    k022: '普通型',
    k023: '舒适型',
    k024: '商务型',
    k025: '豪华型',
    k026: '加载中...',
    k027: '可用城市：',
    k028: '可用地点：',
    k029: '可用模式：',
    k030: '可用车型：',
    k031: '跨城用车：',
    k032: '搜索',
    k033: '可用总额度',
    k034: '请输入场景Code或名称',
    k035: '*满足以上任一使用限制组合即可用车',
    k036: '使用限制',
    k037: '无限制',
    k038: '允许',
    k039: '暂无',
    k040: '无限',
    k041: '周末及法定节假日',
    k042: '无可用时间段',
    k043: '禁止',
    k044: '次日'
  },
  setphone: {
    k001: '手机号',
    k002: '设置手机号',
    k003: '请输入验证码',
    k004: '获取验证码',
    k005: '已发送',
    k006: '该手机号将默认为乘车人手机号。',
    k007: '提交',
    k008: '请输入手机号',
    k009: '请输入正确手机号',
    k010: '请填写图片验证码',
    k011: '发送验证码失败',
  },
  settings: {
    k001: '用车人',
    k002: '审批人',
    k003: '手机号',
    k004: '未设置',
    k005: '用车模式',
    k006: '紧急联系人',
    k007: '语言',
    k008: '确定',
    k009: '智能模式',
    k010: '自选模式',
    k011: '中文',
    k012: '请输入手机号',
    k013: '加载中',
    k014: '保存成功',
    k015: 'Engilish',
    k016: '请输入',
    k017: '请先设置用户手机号',
    k018: '常用地址',
    k019: '去设置',
    k020: '导入通讯录',
    k021: '已导入',
    k022: '未导入',
    k023: '请导入通讯录类型文件',
    k024: '导入成功',
    k025: '导入失败',
    k026: '滴滴录音录像权限',
  },
  share: {
    k001: '分享指引',
    k002: '点击右上角,将行程信息分享给亲友',
    k003: '车牌号',
    k004: '起点',
    k005: '终点',
    k006: '时间'
  },
  trip: {
    k001: '起点：',
    k002: '终点：',
    k003: '元'
  },
  waitingShenzhou: {
    k001: '客服人员正在处理,预约成功后,系统将自动推送车辆司机等信息,请留意查收!',
    k002: '订单详情',
    k003: '起　　点',
    k004: '终　　点',
    k005: '联系电话',
    k006: '车　　型',
    k007: '用车时间',
    k008: '接机礼宾',
    k009: '客服电话',
    k010: '取消订单',
    k011: '座机',
    k012: '确认取消订单?',
    k013: '正在努力为您寻找司机,再等一会吧',
    k014: '继续等待'
  },
  writeInvoice: {
    k001: '抬头类型',
    k002: '企业单位',
    k003: '公司名称',
    k004: '填写需要开具发票的企业名称',
    k005: '公司税号',
    k006: '纳税人识别号',
    k007: '填写开户行及账号',
    k008: '地址',
    k009: '填写地址及电话',
    k010: '总金额',
    k011: '电子邮件',
    k012: '请输入邮箱',
    k013: '请输入备注',
    k014: '确认发票信息',
    k015: '发票类型',
    k016: '电子发票',
    k017: '电子邮箱',
    k018: '确认',
    k019: '提交',
    k020: '加载中',
    k021: '开具成功',
    k022: '发票开具成功，系统将在两个工作日内发送至收件人邮箱',
    k023: '开具失败',
    k024: '关闭',
    k025: '发票开具失败，请稍后重新尝试',
    k026: '系统异常',
    k027: '请填写发票抬头',
    k028: '请输入邮箱',
    k029: '请输入正确邮箱',
    k030: '开户行'
  },
  preApproval: {
    k001: '申请用车',
    k002: '请选择申请单',
    k003: '点击查看',
    k004: '暂无申请单',
    k005: '申请单',
    k006: '请选择',
    k007: '审批中',
    k008: '已同意',
    k009: '审批拒绝',
    k010: '重新提交',
    k011: '拒绝原因：',
    k012: '查看审批记录',
    k013: '申请单号:',
    k014: '行前审批',
    k015: '申请人：',
    k016: '起始地：',
    k017: '目的地：',
    k018: '申请原因：',
    k019: '加载中…',
    k020: '加载失败',
    k021: '待审批',
    k022: '可用时间：',
    k023: '用车场景：',
    k024: '暂无记录',
    k025: '已审批',
    k026: '全选',
    k027: '拒绝',
    k028: '通过',
    k029: '服务错误，查询失败',
    k030: '请先选择至少一条记录',
    k031: '请输入拒绝原因',
    k032: '操作成功',
    k033: '审批失败',
    k034: '起始城市：',
    k035: '目的城市：',
    k036: '可用金额：',
    k037: '可用次数：',
    k038: '元',
    k039: '次',
  },
  prepaid: {
    k001: '支付成功',
    k002: '预付车费',
    k003: '剩余支付时间',
    k004: '请您按照个人支付预估价预付车费，订单完成后，若实际金额小于您支付的预估价，超出部分将原路返还。',
    k005: '付款项目',
    k006: '预付车费',
    k007: '付款方式',
    k008: '微信支付',
    k009: '支付宝支付',
    k010: '查看订单',
    k011: '立即支付',
    k012: '支付超时',
    k013: '支付超时，请重新下单',
    k014: '返回首页',
    k015: '加载中…',
    k016: '支付失败',
    k017: '请确认您是否已完成支付',
    k018: '未支付',
    k019: '已支付',
    k020: '暂未查询到支付结果，若您已经支付，请联系客服',
    k021: '我知道了',
    k022: '支付超时请重新下单，相关金额将于3个工作日内退回',
    k023: '订单生成中，请稍后',
    k024: '京东支付',
    k025: '支付确认',
  },
  title: {
    k001: '请选择车型',
    k002: '确认下单',
    k003: '企业出行条款',
    k004: '知情同意书',
    k005: '预付费',
    k006: '预付费进度',
    k007: '选择优惠券',
    k008: '您的订单已发起预约',
    k009: '等待接单',
    k010: '一键报警',
    k011: '进行中订单',
    k012: '用车场景',
    k013: '优惠券',
    k014: '分享行程',
    k015: '个人支付',
    k016: '企业账户',
    k017: '企业充值',
    k018: '我的行程',
    k019: '订单详情',
    k020: '订单评价',
    k021: '发票',
    k022: '开具电子发票',
    k023: '开票详情',
    k024: '所含行程',
    k025: '审批列表',
    k026: '审批详情',
    k027: '个人中心',
    k028: '紧急联系人',
    k029: '用车报告',
    k030: '无权限',
    k031: '抱歉,出错了',
    k032: '调试信息',
    k033: '分享行程',
    k034: '软件使用协议',
    k035: '登录',
    k036: '注册',
    k037: '修改申请用车',
    k038: '申请用车',
    k039: '申请记录',
    k040: '行前审批',
    k041: '您好,您要去哪?',
    k042: '选择用车场景',
    k043: '场景详情',
    k044: '首页',
    k045: '常用地址',
    k046: '成本中心',
    k047: '会议信息补充',
    k048: '腾讯地图测试',
    k049: '碳积分面板',
    k050: '中间页',
    k051: '支付中心',
    k052: '无用车权限',
    k053: '我的会议',
    k054: '选择会议',
    k055: '费用明细',
    k056: '客服中心',
    k057: '投诉进度详情',
    k058: '异常订单确认'
  },
  habitAddress: {
    k001: '常用地址1',
    k002: '常用地址2',
    k003: '清空地址',
    k004: '取消',
    k005: '确定',
    k006: '确认清空“常用地址1”的配置?',
    k007: '确认清空“常用地址2”的配置?',
    k008: '请选择具体地址',
    k009: '常用地址',
    k010: '去设置'
  },
  common: {
    k001: '取消',
    k002: '确定',
    k003: '我知道了',
    k004:'系统提示',
    k005:'关闭',
    k006:'显示详情',
    k007: '前往支付'
  },
  serviceCenter: {
    k001: '物品遗失',
    k002: '请描述遗失物品情况。',
    k003: '费用问题',
    k004: '请描述您遇到的问题',
    k005: '服务问题',
    k006: '用车信息',
    k007: '待支付',
    k008: '问题类型',
    k009: '提交',
    k010: '联系客服：',
    k011: '提交成功',
    k012: '请描述遗失物品情况。'
  },
  expenseDetails: {
    k001: '总金额',
    k002: '元',
    k003: '约车',
    k004: '预约管家为您代叫不同平台车型的差价已直接扣除，无需由您支付。',
    k005: '对费用有疑问？',
    k006: '点击反馈',
    k007: '※ 若发生退款,部分费用可能将重新计算,请以实际退款金额为准。'
  },
  complaintsDetails: {
    k001: '提交投诉',
    k002: '正在处理',
    k003: '处理完成',
    k004: '用车信息',
    k005: '待支付',
    k006: '投诉详情',
    k007: '联系客服：'
  },
  orderAbnormal: {
    k001: '本次行程需确认异常情况，请在下方填写说明并提交。',
    k002: '本次行程超出以下标准',
    k003: '正常',
    k004: '请填写产生异常的原因， 方便审批人了解情况。（200字内）',
    k005: '对行程异常有疑问？',
    k006: '点击投诉',
    k007: '提交',
    k008: '提交成功'
  },
  special: {
    k001: '请输入成本中心编号或名称',
    k002: '确认加入该成本中心?',
    k003: '名称：',
    k004: '提交',
    k005: '编码：',
    k006: '来源：',
    k007: '删除',
    k008: '暂无数据',
    k009: '新增',
    k010: '确认删除此成本中心?',
    k011: '累计减排',
    k012: '当月减排',
    k013: '累计低碳出行订单数量',
    k014: '当月低碳出行订单数量',
    k015: '排名',
    k016: '部门',
    k017: '减排量',
    k018: '订单占比',
    k019: '开始时间不能小于结束时间',
  },
  meeting: {
    k001: '会议信息',
    k002: '点击选择会议信息',
    k003: '成本中心信息',
    k004: '点击选择成本中心信息',
    k005: '点击选择PO信息',
    k006: 'PO信息',
    k007: '预算金额',
    k008: '页面显示为会议RAE对应的成本中心，打车费用会结算在对应区域安进打车PO，请知晓。',
    k009: '请输入PO代码',
    k010: '请输入成本中心信息代码',
    k011: '请输入会议信息编号或名称',
    k012: '请选择成本中心',
    k013: '请选择PO信息',
  },
  menu: {
    k001: '请联系平台在线客服申请开票',
    k002: '控制台',
    k003: '我的会议',
    k004: '企业已关闭当前业务',
    k005: '预订火车票'
  },
  sanofiMeeting: {
    k001: '添加IO',
    k002: '一键删除',
    k003: '暂无会议',
    k004: '截止日期',
    k005: '展开更多',
    k006: '收起',
    k007: '是否删除所有会议？',
    k008: '删除成功',
    k009: '该会议不可删除',
    k010: '是否删除该会议？',
    k011: '请输入会议编号或名称',
    k012: '确认添加吗？',
    k013: '操作成功',
    k014: 'IOName',
    k015: 'IOCode',
    k016: 'Owner',
    k017: 'ProductCode',
    k018: '会议不可删除',
  },
  agreement: {
    k001: '软件使用协议',
    k002: '1、定义',
    k003: '1.1 【企业出行】平台：以下简称“【企业出行】”，是针对企业客户提供出行服务，企业客户方的用车人员可通过【企业出行】平台自主发送出行需求订单，并在订单完成后实现快捷支付的企业用车服务平台。',
    k004: '1.2订单：【企业出行】平台上开通企业账户的用户方，在该平台上实际发起并完成的有效订单。',
    k005: '1.3用户：使用【企业出行】的企业客户方的员工或其他相关人员，通过该平台自主发送出行需求订单，具有完全民事权利能力和行为能力的自然人或其他符合本条款规定的实际乘车人。',
    k006: '2、法律声明',
    k007: '本《软件使用协议》适用于【企业出行】平台。您在点击同意本协议之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体标识， 您应重点阅读。如您对协议有任何疑问，可向【企业出行】平台客户咨询（咨询电话：）。当您点击同意本协议后，即表示您已充分阅读、理解本协议条款，并接受本协议的全部内容，且成为【企业出行】平台的“企业用户”，本协议对贵司及平台均具有法律约束力。【企业出行】平台有权在必要时修改本协议条款，并以提前公示、推送通知或弹窗等方式通知到您。您可以随时在本平台查阅修改后的最新版本协议。如您不同意修改后的协议，您有权停止使用本平台服务。 本协议更新后，如果您继续使用本平台服务，即视为您已接受修改后的协议。',
    k008: '3、账号使用',
    k009: '用户在使用过程中需支付因个人行为产生的费用，如果出现拖欠，【企业出行】平台有权对账号进行封禁，并追究可能产生的法律责任。',
    k010: '4、权利说明',
    k011: '【企业出行】对其发行的或与合作公司共同发行的包括但不限于提供的软件及相关产品或服务的全部内容，享有知识产权，受法律保护。如果相关内容未含权利声明，并不代表【企业出行】平台对其不享有权利和不主张权利，您应根据法律、法规及诚信原则尊重权利人的合法权益并合法使用该内容。未经【企业出行】平台书面许可，任何单位及个人不得以任何方式或理由对上述软件、产品、服务、信息、文字的任何部分进行使用、复制、修改、抄录、传播或与其它产品捆绑使用、销售，或以超级链路连接或传送、存储于信息检索系统或者其他任何商业目的的使用，但对于非商业目的的，个人使用的下载或打印（未作修改，且须保留该内容中的版权说明或其他所有权的说明）除外。上述软件中使用和显示的商标和标识（以下统称“商标”）是【企业出行】平台及其关联公司在出行服务及其他相关领域内注册和未注册的有关商标，受法律保护。该等软件中所载的任何内容，未经【企业出行】平台书面许可，任何人不得以任何方式使用【企业出行】及近似名称和相关商标、标识。',
    k012: '5、责任限制声明',
    k013: '5.1【企业出行】平台可能为不同的终端设备开发了不同的应用程序版本，用户应根据实际情况选择下载适合的版本进行安装。平台不保证所有的版本与相关设备的适配性和可用性，亦不保证所有的版本提供的服务的一致性。',
    k014: '5.2为了完善平台服务内容，【企业出行】平台将不断努力开发新的服务，并为用户提供应用程序的更新。应用程序新版本发布后，旧版本的应用程序可能无法使用，平台不保证旧版本应用程序可继续使用及相应服务，因此请用户随时核对并下载最新版本的应用程序。',
    k015: '5.3请用户理解并同意：在使用本平台服务的过程中，可能会遇到不可抗力、技术风险等因素，对于下述原因导致的合同履行障碍、履行瑕疵、履行延后或履行内容变更、数据丢失等情形，平台在法律允许的最大范围内免责：',
    k016: 'A、因自然灾害、罢工、劳动争议、暴乱、骚乱、爆炸、战争、政府行为、司法行政命令或其他不可抗力因素；',
    k017: 'B、因电力供应故障、通讯网络故障（包括但不限于电子通讯传达失败或延时、用于电子通讯的计算机程序对电子通讯的拦截或操作）等公共服务因素或用户自身因素（包括但不限于用户操作不当、通过非平台授权的方式使用本服务）或第三人因素（包括但不限于受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏、顾客的错误下单等错误操作）；',
    k018: 'C、在平台已尽善意管理的情况下，因常规或紧急的设备与系统维护、设备与系统故障、西缺陷、网络信息与数据安全、技术风险等因素；',
    k019: 'D、其他平台无法控制或合理预见的情形。',
    k020: '5.4如果平台发现了因系统故障或其他原因导致的处理错误，无论有利于平台还是有利于用户，平台都有权在根据本协议规定通知用户后纠正该错误、回转/回档相关交易或数据。用户理解并同意，因前述处理错误而多付或少付的款项均不计利息，平台不承担因前述处理错误而导致的任何损失或责任（包括用户可能因前述错误导致的利息、汇率等损失），但因平台恶意而导致的处理错误除外。',
    k021: '5.5平台仅对因平台原因给您造成的直接、实际损失依法承担相应的赔偿责任，不对任何间接损失、惩罚性赔偿承担责任。',
    k022: '5.6用户在实际使用【企业出行】的服务时，应当具备中华人民共和国法律规定的完全民事行为能力。若您不具备完全民事行为能力，请您务必在家长和其他法定监护人的同意及全程陪同下使用打车服务。否则您及您的监护人应依照法律规定承担因此而导致的一切后果。',
    k023: '5.7用户同意【企业出行】拥有通过消息推送、平台公示、短信、电话、邮件等形式向您通知告知信息的权利。此外，用户允许【企业出行】平台发送商业性电子信息或广告促销信息。',
    k024: '6、 授权及许可',
    k025: '6.1在用户遵守本协议的前提下，平台授予用户有限的、非排他性的、不可转让的如下许可：以将一份平台应用程序副本下载并安装到用户拥有或控制的但的单台移动设备上，并仅出于用户自身用途运行此应用程序副本。用户不得：',
    k026: '（1）以任何方式许可、再许可、出售、转售、转让、分配、分发服务或应用程序，或以其他方式进行商业开发或提供给任何第三方:',
    k027: '（2）修改平台服务功能或应用程序，或据此创建衍生产品；',
    k028: '（3）创建指向平台服务的互联网“链接”，或在任何其他服务器或基于无线或互联网的设备上“设计”或“镜像”任何应用程序；',
    k029: '（4）反向工程或访问应用程序设计或构建竞争产品或服务、使用类似于平台服务或应用程序的设想或图形来设计或构建产品，或抄袭平台服务或应用程序的任何设想、特点、功能或图形；',
    k030: '（5）启动自动程序或脚本，每秒发送多个服务器请求或过度加重平台服务或应用程序负担或妨碍其工作和/或性能的程序；',
    k031: '6.2在法律允许的最大范围内，平台将有权调查并起诉任何上述违法违规行为。本平台可参与并协助执法部门起诉违反本协议的用户。如果本平台认为任何内容违反本协议或以其他方式危害网站、平台及/或其中的服务或应用程序，本平台保留在不另行通知的情况下随时删除或禁用对这些内容的访问权限的权利。',
    k032: '7、知识产权',
    k033: '7.1【企业出行】平台所展示的各运营系统由【企业出行】自主开发、运营及提供技术支持，并对【企业出行】服务的开发和运营等过程中产生的所有数据和信息等享有全部权利。【企业出行】提供各项服务时所依托软件的著作权、专利权，所使用的各项商标、商业形象、商业标识、技术诀窍，其著作权、商标权及其他各项相关权利均归【企业出行】平台所有。',
    k034: '7.2用户理解并同意：用户在使用【企业出行】过程中所产生、上传的内容等所有信息的知识产权及所有权，归【企业出行】平台所有。',
    k035: '8、知识产权',
    k036: '【企业出行】平台上可能保留有第三方网站（或应用程序）或网址的链接及第三方服务，是否访问这些链接或接受相应服务将由用户自己做出决定，平台并不就这些链接所提供的任何信息、数据、观点、图片、陈述或建议的准确性、完整性、充分性和可靠性提供承诺或保证。如果用户决定访问任何与本平台链接的第三方网站（或应用程序），其可能带来的结果和风险全部由用户自己承担。',
    k037: '9、适用法律和管辖权',
    k038: '通过访问【企业出行】平台提供的或其合作服务提供商提供的服务，即表示用户同意该访问或服务受中华人民共和国法律的约束，且用户同意受中华人民共和国法院的管辖。访问或接受服务过程中发生的争议应当协商解决，协商不成的，应向协议签署地有管辖权的人民法院提起诉讼，本协议签订地为中华人民共和国上海市宝山区。'
  }
}