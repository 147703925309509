// import { dge } from './utils'
import {
    envName,
    qqmapBaseUrl,
    amapBaseUrl,
    qqKey,
    amapKey,
    amapUIKey,
    gatewayDomain,
    gatewaycore,
    gatewayDomainGray,
    gatewaycoreGray,
    versionNumber
} from './variable'

import {
    stringToBase64Safe
} from './utils'

import {
    oauthType,
    appId
} from './globalConfiguration'

import gc from './../util/globalConfiguration'

// 高德地图2.0 key(ui用)
const webConfig = {
    version: '2.0',
    key: amapUIKey
};

const webBaseUrl = window.location.origin + (window.location.pathname.endsWith('/') ? window.location.pathname.substring(0, window.location.pathname.length - 1) : window.location.pathname);
const serverBaseUrl = `https://${gatewayDomain}/api/v2`;
// const wssRuningOrderUrl = `wss://${gatewaycore}/websocket`;

const garyAdapter = {
    get serverBaseUrl() {
      let grayscale = sessionStorage.getItem('grayscale')
      if (grayscale) {
        return `https://${gatewayDomainGray}/api/v2`;
      } else {
        return `https://${gatewayDomain}/api/v2`;
      }
    },

    get wssRuningOrderUrl() {
        let grayscale = sessionStorage.getItem('grayscale')
        if (grayscale) {
          return `wss://${gatewaycoreGray}/websocket`;
        } else {
          return `wss://${gatewaycore}/websocket`;
        }
    }
  }


// 判断当前环境，动态加载JSSDK
let jsSdkLoaded = false
const loadJsSdk = () => {
    return new Promise((resolve, reject) => {
        switch(oauthType){
            case '2': {
                if (!jsSdkLoaded) {
                    let script = document.createElement('script');
                    script.id = 'oauthType_2'
                    script.type = 'text/javascript';
                    script.onload = () => { 
                        jsSdkLoaded = true;
                        resolve();
                    };
                    script.src = "https://lf1-cdn-tos.bytegoofy.com/goofy/lark/op/h5-js-sdk-1.5.13.js";
                    document.getElementsByTagName('head')[0].appendChild(script);
                } else {
                    resolve();
                }
                break;
            }
            case "1" || '11' || '7': {
              if (!jsSdkLoaded) {
                let script = document.createElement('script');
                script.id = "oatuthType_jweixin";
                script.type = 'text/javascript';
                script.onload = () => {
                  resolve();
                }
                script.src = 'https://res.wx.qq.com/open/js/jweixin-1.2.0.js';
                document.getElementsByTagName('head')[0].appendChild(script);
                
                let script_2 = document.createElement('script');
                script_2.id = "oatuthType_jwxwork";
                script_2.type = 'text/javascript';
                script_2.onload = () => {
                  jsSdkLoaded = true;
                  resolve();
                }
                script_2.src = 'https://open.work.weixin.qq.com/wwopen/js/jwxwork-1.0.0.js';
                document.getElementsByTagName('head')[0].appendChild(script_2);
              } else {
                resolve();
              }
              break;
            }
            case '17': {
                // 强生
                let key = ''
                switch (envName) {
                    case 'jnjtest':
                        {
                            // 测试应用
                            key = '942c23770df672bc'
                            break;
                        }
                    case 'prod21':
                        {
                            // 生产环境
                            key = '57093d76d4252811'
                            break;
                        }
                    default: {
                        
                    }
                }

                if (!jsSdkLoaded) {

                    let loadJsConfig = () => {
                        let script_2 = document.createElement('script');
                        script_2.id = "oatuthType_jwxwork";
                        script_2.type = 'text/javascript';
                        script_2.onload = () => {
                            jsSdkLoaded = true;
                            resolve();
                        }
                        let _uri = encodeURIComponent(webBaseUrl)
                        let debug = true
                        window.alert = (a) => {
                            console.log(a)
                        }
                        script_2.src = `https://jjmcopen.vhdong.com/api/config.js?key=${key}&debug=${debug}&url=${_uri}`;
                        document.getElementsByTagName('head')[0].appendChild(script_2);
                    }


                    let script = document.createElement('script');
                    script.id = "oatuthType_jweixin";
                    script.type = 'text/javascript';
                    script.onload = () => {
                        loadJsConfig();
                    }
                    script.src = 'https://res.wx.qq.com/open/js/jweixin-1.1.0.js';
                    document.getElementsByTagName('head')[0].appendChild(script);
                    
                    
                } else {
                    resolve();
                }
                break;
            }
            default: {}   
        }
    })
    
    
}
// 判断当前环境，动态卸载JSSDK
const unLoadJsSdk = () => {
    if (oauthType === '2') {
        let dom = document.getElementById('oauthType_' + oauthType)
        if (dom) {
          dom.remove()
        }
        jsSdkLoaded = false;
    } else if (oauthType === '1' || oauthType === '11' || oauthType === '7') {
      let dom = document.getElementById('oatuthType_jweixin');
      if (dom) {
        dom.remove();
      }
      let dom_2 = document.getElementById('oatuthType_jwxwork');
      if (dom_2) {
        dom_2.remove();
      }
      jsSdkLoaded = false;
    }
    
}

const customerServiceTelephone = gc.process.isMeituan ? '4000210002' : '400-075-7666'
const customerServiceTelephone_ziru = '02558785999'

const payMethod_WeiXin = 3;
const payMethod_DingDing = 4;

// 设备
let ua = '';
// oauth2重定向路径
let oatuth2_url = '';

// 构造oauth重定向地址
(() => {
    if(oauthType && appId) {
        switch(oauthType) {
            case '1': {
                // 企业微信第三方应用（商城版本）
                oatuth2_url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=$redirect_uri$&response_type=code&scope=SCOPE&state=STATE#wechat_redirect`;
                oatuth2_url = oatuth2_url.replace('$redirect_uri$', encodeURIComponent(`${webBaseUrl}/?oauth=${oauthType}`));
                break;
            }
            case '2': {
                // 飞书商城版本
                oatuth2_url = `https://open.feishu.cn/open-apis/authen/v1/index?redirect_uri=$redirect_uri$&app_id=${appId}&state=`;
                oatuth2_url = oatuth2_url.replace('$redirect_uri$', encodeURIComponent(`${webBaseUrl}/?oauth=${oauthType}`));
                break;
            }
            case '7': {
                // E多
                switch (appId) {
                    case '181052736110855941':
                        // 参天
                        if (envName === 'santentest') {
                            oatuth2_url = 'https://m-qyweixin.santen-china.cn/s2/62fdb6879c4090e5'
                        } else if (envName === 'santenprod') {
                            oatuth2_url = 'https://m-qyweixin.santen-china.cn/s2/7fb805c674a810ca'
                        }
                        break;
                    case '200064783432739':
                        // 安进
                        if (envName === 'anjinprod') {
                            oatuth2_url = 'https://m.amgenwechat.com/s/54647ea20fed6d93'
                        }
                        break;
                    case '196436570868287237':
                        oatuth2_url = 'https://m.amgenwechat.com/s/783aeeceae6f069d'
                        break;
                    case '196659580615264005':
                        oatuth2_url = 'https://m.amgenwechat.com/s/783aeeceae6f069d'
                        break;  
                    case '233060266472900357':
                        // AZ测试环境
                        oatuth2_url = 'https://m.astrazeneca.cn/s/42fbcd5073058ec7'
                        break;
                    case '450000000000000':
                        // AZ生产环境
                        oatuth2_url = 'https://m.astrazeneca.cn/s/53b9fd991a8e0e04'
                        break;
                     
                    default:
                        break;
                }
                break;
            }
            case '11': {
                // 企业微信（自建应用）
                let _item_array = appId.split('@');
                let corpId = _item_array[1];
                let agentId = _item_array[0];
                oatuth2_url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${corpId}&redirect_uri=$redirect_uri$&response_type=code&scope=snsapi_base&state=STATE&agentid=${agentId}#wechat_redirect`
                oatuth2_url = oatuth2_url.replace('$redirect_uri$', encodeURIComponent(`${webBaseUrl}/`));
                break;
            }
            case '12': {
                // 微信服务号
                let redirect_uri = encodeURIComponent(`${webBaseUrl}/`)
                oatuth2_url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=#wechat_redirect`
                break;
            }
            case '13': {
                // 钉钉自建应用
                break;
            }
            case '16': {
                // 武田自建应用
                switch (envName) {
                    case 'test':
                        {
                            // 测试应用
                            let _key = '3CMkD4OjECg5Jrag4AdHA'
                            let _sceret = 'ou8BbuTMc0S1LzdhoR3afw'
                            let _domain = 'wechat-test.takeda.com.cn'
                            let _redirectUrl = encodeURIComponent(`${webBaseUrl}/`)
                            oatuth2_url = `https://${_domain}/OAuth2/GetUserInfoByAccessApp?key=${_key}&secret=${_sceret}&redirectUrl=${_redirectUrl}`
                            break;
                        }
                    case 'prod21':
                        {
                            // 生产环境
                            let _key = 'Th8eEBxhEU6k50MfTVRUfg'
                            let _sceret = 'SdaG5iJfhUWSeDVI74L1Aw'
                            let _domain = 'wechat.takeda.com.cn'
                            let _redirectUrl = encodeURIComponent(`${webBaseUrl}/`)
                            oatuth2_url = `https://${_domain}/OAuth2/GetUserInfoByAccessApp?key=${_key}&secret=${_sceret}&redirectUrl=${_redirectUrl}`
                            break;
                        }
                    default: {
                        
                    }
                }
                break;
            }
            case '17': {
                // 强生
                switch (envName) {
                    case 'jnjtest':
                        {
                            // 测试应用
                            let _client_id = 'jcar-qa'
                            let _domain = 'fedlogin.jnj.com'
                            // let _redirectUrl = encodeURIComponent(`${webBaseUrl}/`)
                            let _redirectUrl = encodeURIComponent(`https://jcarqa.jjmc.cn/17/jnj/`)
                            oatuth2_url = `https://${_domain}/as/authorization.oauth2?client_id=${_client_id}&response_type=code&redirect_uri=${_redirectUrl}&scope=address%20email%20openid%20phone%20profile`
                            break;
                        }
                    case 'jnjprod':
                        {
                            // 生产环境
                            let _client_id = 'jcar-prod'
                            let _domain = 'fedlogin.jnj.com'
                            //let _redirectUrl = encodeURIComponent(`${webBaseUrl}/`)
                            let _redirectUrl = encodeURIComponent(`https://jcar.jjmc.cn/17/jnj/`)
                            oatuth2_url = `https://${_domain}/as/authorization.oauth2?client_id=${_client_id}&response_type=code&redirect_uri=${_redirectUrl}&scope=address%20email%20openid%20phone%20profile`
                            break;
                        }
                    default: {
                        
                    }
                }
                break;
            }
            case '18': {
                // 辉瑞
                switch (appId) {
                    case '1885541299':
                        // 测试环境
                        {
                            let agentId = 1000012;
                            let _domain = 'pfizeruat.tsepcn.com';
                            let _redirectUrl = webBaseUrl + '/?code='
                            _redirectUrl = stringToBase64Safe(_redirectUrl)

                            oatuth2_url = `https://${_domain}/japi/qiye/oauth/ticket/${agentId}/${_redirectUrl}`
                            //console.log(oatuth2_url)
                            //alert(oatuth2_url)
                            break;
                        }
                    case '1045507440':
                        // 生产环境
                        {
                            let agentId = 1000038;
                            let _domain = 'pfizer.tsepcn.com';
                            let _redirectUrl = webBaseUrl + '/?code='
                            _redirectUrl = stringToBase64Safe(_redirectUrl)

                            oatuth2_url = `https://${_domain}/japi/qiye/oauth/ticket/${agentId}/${_redirectUrl}`
                            //console.log(oatuth2_url)
                            //alert(oatuth2_url)
                            break;
                        }
                    default: {
                    
                    }
                }
                break;
            }
            case '19': {
                // 赛诺菲
                switch (envName) {
                    case 'test':
                        // 测试环境 get{host}/services/authorize		
                        {
                            let _domain = 'emp.uat.sanofidigital.cn';
                            let _redirectUrl = encodeURIComponent(webBaseUrl)

                            oatuth2_url = `https://${_domain}/services/authorize?redirect_uri=${_redirectUrl}`
                            break;
                        }
                    case 'prod21':
                        // 生产环境
                        {
                            let _domain = 'emp.sanofidigital.cn';
                            let _redirectUrl = encodeURIComponent(webBaseUrl)

                            oatuth2_url = `https://${_domain}/services/authorize?redirect_uri=${_redirectUrl}`
                            break;
                        }
                    default: {
                    
                    }
                }
                break;
            }
            default: {

            }
            
        }
    } else {
        // 前端无法发起身份验证动作
    }
    
})()

// 支付中心重定向
// const ipay_url = 'https://ipay.imtpath.net/Home/CashierDesk?payOrderid=$payOrderid$&callBackUrl=$callBackUrl$&appid=$appid$';
let ipay_url = '';

if (process.env.REACT_APP_env_name.includes('prod')) {
  ipay_url = 'https://prd-std-vone.imtpath.net/paycenter/CashierDeskPay.html?orderId=$payOrderid$&callbackUrl=$callBackUrl$&appid=$appid$&preEnv=$preEnv$'
} else {
  ipay_url = 'https://tstmobilev2.imtpath.net/paycenter/CashierDeskPay.html?orderId=$payOrderid$&callbackUrl=$callBackUrl$&appid=$appid$&preEnv=$preEnv$'
}

// 飞书外部启动路径url
// const feishu_open_url = `https://applink.feishu.cn/client/op/open`
const feishu_open_url = `feishu://${webBaseUrl}`
// const feishu_open_url = `https://applink.feishu.cn/client/web_app/open?appId=${feishuAppId}&path=`

// 梓如收银台
let ziruPayUrl = ''
if (oauthType === '6' && (appId === 'ziru' || appId === 'ziruziru')) {
    switch (envName) {
        case 'prod21':
            ziruPayUrl = 'https://nwgateway.shanglv51.com/domesticcar/yiQi/getCashDeskUrl'
            break;
        default: {
            ziruPayUrl = 'https://nwgateway.shanglv51.com/domesticcar/yiQi/getCashDeskUrl'
        }
    }
}


const ES_SuiXingWeiShi = 'ES0002';
const ES_JieJiLiBin = 'ES0003';

const GEO_TIMEOUT = 10 * 1000;
// : garyAdapter.wssRuningOrderUrl

// const serverBaseUrl = garyAdapter.serverBaseUrl
// const wssRuningOrderUrl = garyAdapter.wssRuningOrderUrl


window.garyAdapter = garyAdapter
export {
    webBaseUrl,
    oauthType,
    appId,
    serverBaseUrl,
    // wssRuningOrderUrl,
    garyAdapter,
    qqmapBaseUrl,
    amapBaseUrl,
    qqKey,
    amapKey,
    customerServiceTelephone,
    customerServiceTelephone_ziru,
    payMethod_WeiXin,
    payMethod_DingDing,
    ua,
    oatuth2_url,
    ipay_url,
    feishu_open_url,
    ES_SuiXingWeiShi,
    ES_JieJiLiBin,
    loadJsSdk,
    unLoadJsSdk,
    ziruPayUrl,
    versionNumber,
    GEO_TIMEOUT
}

export default webConfig;