import React from 'react'
import style from './countDown.scss';
import {inject, observer } from 'mobx-react'

import moment from 'moment'

import {secondTohh_mm_ss} from './../../util/utils'

@inject('runingOrderStore', 'globalStore')
@observer
class CountDown extends React.Component {

    state = {
        waitingTime: '00:00'
    }

    globalStore = this.props.globalStore;

    runingOrderStore = this.props.runingOrderStore;
    timerWaiting = 0;

    t = this.props.t

    render() {
        return <div className={style.waitingTime}>
            <div>
                <div>{this.t('runing.k012')}</div>
                <div>{this.state.waitingTime}</div>
            </div>                    
        </div>
    }

    componentDidMount() {
      this.timerWaiting = setInterval(() => {
        let s = moment().diff(moment(this.props.createTime), 's')
        if (s < 0) s = 0
        let waitingTime = secondTohh_mm_ss(Math.trunc(Number(s)))

        this.setState({
            waitingTime: waitingTime
        });
        }, 1000);
    }

    componentWillUnmount() {
        if (this.timerWaiting) {
            clearInterval(this.timerWaiting)
        }
        
    }

}

export default CountDown;